import { useEffect, useMemo } from 'react';
import { isNumber } from 'lodash';

export const dropdownPositionOptions = ['top','top-start','top-end','left','left-start','left-end','right','right-start','right-end','bottom','bottom-start','bottom-end'];

export function useDropdownPlacementConversion(position) {
  return useMemo(() => {
    let placement;
    switch (position) {
      case 'top-left':
        placement = 'top-end';
        break;
      case 'top-right':
        placement = 'top-start';
        break;
      case 'top':
        placement = 'top-start';
        break;
      case 'left-top':
        placement = 'left-end';
        break;
      case 'left-bottom':
        placement = 'left-start';
        break;
      case 'left':
        placement = 'left-start';
        break;
      case 'right-top':
        placement = 'right-end';
        break;
      case 'right-bottom':
        placement = 'right-start';
        break;
      case 'right':
        placement = 'right-start';
        break;
      case 'bottom-left':
        placement = 'bottom-end';
        break;
      case 'bottom-right':
        placement = 'bottom-start';
        break;
      case 'bottom':
        placement = 'bottom-start';
        break;
      default:
        placement = position;
    }
    return placement;
  }, [position]);
}

export const hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn({ hide }) {
    function onKeyDown(event) {
      if (event.keyCode === 27) hide();
    }
    return {
      onShow: () => document.addEventListener('keydown', onKeyDown),
      onHide: () => document.removeEventListener('keydown', onKeyDown),
    };
  },
};

export function useAutoDisable(disabled, isOpen, close) {
  useEffect(() => {
    if (disabled && isOpen) close();
  }, [disabled]);
}

export function useContentWidth(contentWidth) {
  return useMemo(() => {
    let width;
    if (isNumber(contentWidth)) {
      width = `${contentWidth / 10}rem`;
    } else if (contentWidth === 'sm') {
      width = '18rem';
    } else if (contentWidth === 'md') {
      width = '28rem';
    } else if (contentWidth === 'lg') {
      width = '40rem';
    } else {
      width = 'auto';
    }
    return width;
  }, [contentWidth]);
}

export function getAnimationDuration() {
  return parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--cp-dropdown-duration').replace('ms', ''));
}