import React from 'react';
import { a } from 'kremling';

import { CpIcon, CpNameChip } from '@components';
import { useHighlighter } from '@hooks';
import { ItemBase } from './item-base';

export function ItemSingle(props) {
  const { isSelected, value, item, onChange, searchValue } = props;
  const isActive = !!(item && value && item.id === value.id);
  const onClick = () => {
    onChange(item);
  };

  const name = useHighlighter(item.name, searchValue);
  const subName = useHighlighter(item.subName, searchValue);

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a('item-single').m('item-single--selected', isSelected)}
      onClick={onClick}
      subHeader={item.subHeader}
    >
      <div
        className="item-single-content cp-flex-center"
        style={{ marginLeft: 16 * (item.indent || 0) }}
      >
        {item.icon && (
          <CpIcon
            className="cp-mr-8"
            name={item.icon}
            fill="var(--cp-color-input-disabled-search-icon)"
          />
        )}
        {item.namechip && (
          <CpNameChip
            className="cp-mr-8"
            size="medium"
            name={item.namechip.name}
            imgSrc={item.namechip.imgSrc}
          />
        )}
        <div className="cp-ellipsis">
          <div className="item-single-content__name">{item ? name : '—'}</div>
          {item.subName && (
            <div className="item-single-content__sub-name cp-ellipsis">
              {subName}
            </div>
          )}
        </div>
      </div>
      <div>
        {isActive && (
          <CpIcon
            name="checkmark-circle-filled-small"
            fill="var(--cps-color-success)"
          />
        )}
      </div>
    </ItemBase>
  );
}
