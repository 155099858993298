import React from 'react';
import styles from './footer.styles.css';
import {CprButton} from '../../../cps-button/cps-button.component.js'

export default function Footer(props) {
  const {setCurrentlySelectedOptions, selections, setCurrentFocus, hideOptions, buttonId, updateSelections, currentlySelectedOptions} = props
  
  return (
    <div className={styles.footer}>
      <CprButton
        actionType='flat'
        onClick={focusOnDone}
        onKeyDown={keyDown}
        type="button"
        tabIndex={0}
        id={'done-' + buttonId}
      >
        Done
      </CprButton>
    </div>
  )
  
  function keyDown(evt) {
    if (evt.key === 'Tab') {
      setCurrentlySelectedOptions(selections)
      hideOptions()
    }
    
    if (evt.key === 'Enter') {
      evt.preventDefault()
      focusOnDone()
    }
  }
  
  function focusOnDone() {
    setCurrentFocus(null)
    hideOptions()
    updateSelections(currentlySelectedOptions)
    const button = document.getElementById(buttonId)
    button && button.focus()
  }
}