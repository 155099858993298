import {kebabCase, camelCase, debounce} from 'lodash';
import ReactDOM from 'react-dom';
import React from 'react';

export function reactToCustomElement(reactComponent, opts) {
  if (!opts.parentClass) {
    opts.parentClass = HTMLElement
  }

  class ReactCustomElement extends opts.parentClass {
    constructor() {
      super();
      this.render = debounce(this._render, 5);
    }
    connectedCallback() {
      opts
      .properties
      .filter(property => !this[property])
      .forEach(property => {
        const attrName = kebabCase(property);
        if (this.hasAttribute(attrName)) {
          this.attributeChangedCallback(attrName, undefined, this.getAttribute(attrName));
        }
      });
      this.connected = true;

      this.render();
    }
    connected = false
    disconnectedCallback() {
      this.connected = false;
      this.render();
    }
    static get observedAttributes() {
      return opts.properties.map(kebabCase);
    }
    attributeChangedCallback(name, oldValue, newValue) {
      this[camelCase(name)] = newValue;
    }
    _render = () => {
      if (!this.connected) {
        if (this.reactRoot().parentNode) {
          ReactDOM.unmountComponentAtNode(this.reactRoot())
        }
        return;
      }

      opts.properties.forEach(property => {
        if (Object.hasOwnProperty(this, property)) {
          // Make sure the getter and setter for the property are being used.
          const existingValue = this[property];
          delete this[property];
          this[property] = existingValue;
        }
      });

      const props = opts.properties.reduce((res, property) => {
        res[property] = this[property];
        return res;
      }, {});
      props.customElement = this;

      ReactDOM.render(React.createElement(reactComponent, props), this.reactRoot())
    }
    reactRoot = () => {
      if (!this._reactRoot) {
        /* We're doing something weird here. But at least it's intentional.
         *
         * If we call ReactDOM.render with the actual custom element as the container element for React,
         * that will replace all innerHTML of the custom element with what is rendered by React. But that's
         * undesireable for our situation with custom elements, because we sometimes pass innerText or innerHTML
         * to custom elements (e.g. innerText for <button is="cps-button">Hi I'm the Button Text</button>).
         *
         * At first I thought this was going to be a total roadblack for us. But then I found the pull request linked to
         * below. It allows you to give a dom comment node to react as the container element, but then will render the react
         * elements into the parent of the comment _*without replacing all the contents*_. So it's a bit weird, but it is
         * something that React supports.
         *
         * https://github.com/facebook/react/pull/9835/files
         */
        this._reactRoot = document.createComment(' react-mount-point-unstable ')
        this.appendChild(this._reactRoot)
      }

      return this._reactRoot
    }
  }

  opts.properties.forEach(property => {
    if (camelCase(property) !== property) {
      throw new Error(`property '${property}' is not camel cased, but all custom element properties should be camel cased.`);
    }
    const privatePropertyName = '_' + property;
    Object.defineProperty(ReactCustomElement.prototype, privatePropertyName, {enumerable: false, writable: true, configurable: false});
    Object.defineProperty(ReactCustomElement.prototype, property, {
      get() {
        return this[privatePropertyName];
      },
      set(newVal) {
        if (this[privatePropertyName] !== newVal) {
          this[privatePropertyName] = newVal;
          this.render();
        }
      },
      enumerable: true,
      configurable: true,
    });
  });

  return ReactCustomElement;
}
