import React, { useEffect, useState } from 'react'
import { string, func, bool } from 'prop-types'
import { isEmpty } from 'lodash'
import isURL from 'validator/lib/isURL'

import { CpInput } from '@components'

Url.propTypes = {
  isValid: bool,
  setIsValid: func,
  value: string
}

export function Url(props) {
  const [isValidDefault, setIsValidDefault] = useState(true)

  const {
    isValid = isValidDefault,
    setIsValid = setIsValidDefault,
    value,
    ...inputProps
  } = props

  useEffect(() => {
    setIsValid(isEmpty(value) || isURL(value))
  }, [value])

  return (
    <CpInput
      error={!isValid && "Invalid URL"}
      value={value}
      {...inputProps}
    />
  )
}
