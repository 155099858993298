import React, { useContext } from 'react';
import { string } from 'prop-types';
import { useCss, k } from 'kremling';

import { CpButton, CpHeader } from '@components';
import { ModalContext } from '../modal.utils';

export const ModalHeader = ({ title, children, ...rest }) => {
  const { onClose } = useContext(ModalContext);
  const scope = useCss(styles);
  return (
    <CpHeader {...rest}>
      <div className="modal-header-title" {...scope}>{title}</div>
      <div className="modal-header-actions" {...scope}>
        <div className="cp-mr-16">
          {children}
        </div>
        <CpButton icon="close-small" aria-label="close" onClick={onClose} />
      </div>
    </CpHeader>
  );
}

ModalHeader.propTypes = {
  title: string,
};

const styles = k`
  .modal-header-title {
    font-size: 1.8rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .modal-header-actions {
    display: flex;
    align-items: center;
    margin-right: -.4rem;
  }
`;
