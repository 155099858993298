import React from 'react';
import { CpToggle } from '@components';
import { useController } from 'react-hook-form';
import { any, object, string } from 'prop-types';

export function CpToggleField(props) {
  const { fieldName, control, required, rules, ...cpToggleProps } = props;
  const {
    field: { value, onBlur, onChange },
  } = useController({ name: fieldName, control, rules });

  return (
    <CpToggle
      {...cpToggleProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

CpToggleField.propTypes = {
  control: any.isRequired,
  fieldName: string.isRequired,
  rules: object,
};
