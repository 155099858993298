import React from 'react';
import { partial } from 'lodash';

import { granularityOptions } from './cps-datepicker.helpers.js';
import styles from './cps-datepicker.styles.css';

export default class Header extends React.Component {
  render() {
    const { changeGranularity, changeRange, granularity, width } = this.props;
    const currentlyDisplaying = getAriaLabelDisplayName(granularity);

    return (
      <div
        style={{
          padding: `0px ${width / 10}px`,
          paddingBottom: '8px',
        }}
        className={`${styles.header} cps-light-gray`}>
        <span
          className={`cps-icon cps-icon-sm-caret-left ${styles.headerArrow}`}
          onClick={partial(changeRange, -1)}
          role="button"
          aria-label={`Previous ${currentlyDisplaying}`}
        />
        <span
          className={`${styles.headerDisplay}`}
          onClick={partial(changeGranularity, getGranularityChange(granularity))}>
          {this.getHeaderDisplay()}
        </span>
        <span
          className={`cps-icon cps-icon-sm-caret-right ${styles.headerArrow}`}
          onClick={partial(changeRange, 1)}
          role="button"
          aria-label={`Next ${currentlyDisplaying}`}
        />
      </div>
    );
  }

  getHeaderDisplay = () => {
    const { viewDate, granularity } = this.props;

    if (granularity === granularityOptions.DAY) {
      return viewDate.format('MMMM YYYY');
    } else if (granularity === granularityOptions.MONTH) {
      return viewDate.year();
    } else if (granularity === granularityOptions.YEAR) {
      const prefix = Math.floor(viewDate.year() / 10);
      return `${prefix}0 - ${prefix}9`;
    } else if (granularity === granularityOptions.DECADE) {
      const prefix = Math.floor(viewDate.year() / 100);
      return `${prefix}00 - ${prefix}90`;
    }
  };
}

function getAriaLabelDisplayName(granularity) {
  switch (granularity) {
    case granularityOptions.DAY:
      return granularityOptions.MONTH;
    case granularityOptions.MONTH:
      return granularityOptions.YEAR;
    case granularityOptions.YEAR:
      return granularityOptions.DECADE;
    case granularityOptions.DECADE:
      return granularityOptions.CENTURY;
  }
}

function getGranularityChange(granularity) {
  if (granularity === granularityOptions.DAY) return granularityOptions.MONTH;
  if (granularity === granularityOptions.MONTH) return granularityOptions.YEAR;
  if (granularity === granularityOptions.YEAR) return granularityOptions.DECADE;
  if (granularity === granularityOptions.DECADE) return granularityOptions.DECADE;
}
