import {asyncStacktrace, catchSyncStacktrace} from 'auto-trace';

if (!DOCUMENTATION_WEBSITE) { // eslint-disable-line
  Promise.all([window.SystemJS.import('fetcher!sofe'), window.SystemJS.import('canopy-urls!sofe')]).then(values => {
    const [fetcher, canopyUrls] = values

    // only do this on white labeled client portals
    if(canopyUrls.default.getBrandingEnvironment() === canopyUrls.default.WHITELABEL_ENVIRONMENT) {
      // note that we don't dispose of the obs, since other things like login-ui and primary-navbar will get it later too, and disposing of it here will bust the cache
      fetcher.fetchWithSharedCache('/white-label-settings', window.appIsMobile ? 'm/' : '#/')
        .subscribe(
          settings => {
            if(settings && settings.color_hex) {
              const [red, green, blue] = settings.color_hex
                .replace(
                  /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                  (m, r, g, b) => "#" + r + r + g + g + b + b
                )
                .substring(1)
                .match(/.{2}/g)
                .map((x) => parseInt(x, 16));
              document.documentElement.style.setProperty('--cps-color-primary', settings.color_hex);
              document.documentElement.style.setProperty('--cps-color-primary-rgb', `${red}, ${green}, ${blue}`);
              document.documentElement.style.setProperty('--cps-color-callout-text', document.documentElement.style.getPropertyValue('--cps-color-primary'));
              document.documentElement.style.setProperty('--cps-color-callout-text-rgb', document.documentElement.style.getPropertyValue('--cps-color-primary-rbg'));

              document.documentElement.style.setProperty('--cp-color-app-primary', settings.color_hex);
              document.documentElement.style.setProperty('--cp-color-app-primary-rgb', `${red}, ${green}, ${blue}`);

              const metaThemeColorTag = document.querySelector("meta[name=theme-color]")
              if(metaThemeColorTag) {
                metaThemeColorTag.setAttribute("content", settings.color_hex);
              }
            }
            if(settings && settings.company_name) {
              document.title = `${settings.company_name} - Client Portal`
            } else {
              document.title = `Client Portal`
            }
          },
          asyncStacktrace((err) => {
            if(!(err.status === 400 || err.status === 404)) {
              // 400 means that this domain requires a redirect
              // 404 means someone put a random/junk domain into the url bar.
              // login-ui will handle these situations, so no need for styleguide to have to worry or report any errors about it here
              catchSyncStacktrace(err);
            }
          })
        )

      // favicon
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.rel = 'shortcut icon'
      link.href = 'https://cdn.canopytax.com/static/style-guide/client-portal-favicon.png'
      document.getElementsByTagName('head')[0].appendChild(link)
    }

  })
}
