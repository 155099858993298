// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cps-timezone-styles__dropdown--MSBNR {\n  max-height: 344px;\n  min-width: 100%;\n  overflow-y: auto;\n}\n\n.cps-timezone-styles__dropdownEmptyState--nNXQv {\n  text-align: center;\n}\n\n.cps-timezone-styles__dropdownEmptyHeader--CjlKG {\n  font-size: 14px;\n  font-weight: 500;\n  padding: 6px 0 8px;\n}\n\n.cps-timezone-styles__dropdownEmptyBody--LJcC5 {\n  font-size: 12px;\n  padding: 0 8px 8px;\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-timezone/cps-timezone.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".dropdown {\n  max-height: 344px;\n  min-width: 100%;\n  overflow-y: auto;\n}\n\n.dropdownEmptyState {\n  text-align: center;\n}\n\n.dropdownEmptyHeader {\n  font-size: 14px;\n  font-weight: 500;\n  padding: 6px 0 8px;\n}\n\n.dropdownEmptyBody {\n  font-size: 12px;\n  padding: 0 8px 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "cps-timezone-styles__dropdown--MSBNR",
	"dropdownEmptyState": "cps-timezone-styles__dropdownEmptyState--nNXQv",
	"dropdownEmptyHeader": "cps-timezone-styles__dropdownEmptyHeader--CjlKG",
	"dropdownEmptyBody": "cps-timezone-styles__dropdownEmptyBody--LJcC5"
};
export default ___CSS_LOADER_EXPORT___;
