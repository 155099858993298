import React, {useState, useEffect, useMemo} from 'react'
import {customElementToReact} from '../custom-element-to-react.js';
import {reactToCustomElement} from '../react-to-custom-element.js';
import {CprButton} from '../cps-button/cps-button.component.js'
import {a, t, k, useCss } from 'kremling'
import CpsIcon from '../cps-icon/cps-icon.component.js'
import Listbox from './listbox/listbox.component.js'

let selectId = 0

export default function CpsShortMultiSelect(props) {
  const {options, optionsSelected, value, labelText, placeholderText, ariaLabelText, isDisabled, width, isListWrapped, listWidth, initialSelection} = props
  const [showOptions, setShowOptions] = useState(false)
  const [selections, setSelections] = useState(value || initialSelection || [])
  const scope = useCss(styles);
    
  const selectLabelText = labelText ? labelText : ariaLabelText
  const placeholder = placeholderText ? placeholderText : 'Select...'
  const instanceId = useMemo(() =>`multi-select-${selectId++}`, [])
  const labelId = instanceId + '-label'
  const buttonId = instanceId + '-button'
    
  const error = useRequiredPropCheck([{ariaLabelText}, {labelText}, {options}, {optionsSelected}]);
        
  if (error) {
    return null
  } 
  
  return (
    <div {...scope} className="select">
      <label className={t('hidden', 'label', (!labelText))}>
        {selectLabelText}
      </label>
      <label aria-label={ariaLabelText} id={labelId} className="hidden">
        {ariaLabelText}
      </label>
      <CprButton
        actionType="unstyled"
        type="button"
        id={buttonId}
        aria-haspopup="listbox"
        aria-labelledby={`${labelId}`}
        disabled={isDisabled}
        onClick={() => toggleShowOptions()}
        className={a('button').m('unselected', !selections).m('optionsOpen', showOptions)}
        style={{width: `${width}`}}
        onKeyDown={keyDown}
        >
        <div className="noOverflow">{selections.length ? selections.length + ' selected ' : placeholder}</div>
        <div className="caret">
          <CpsIcon
            name="sm-caret-down"
            size={24}
            />
        </div>
      </CprButton>
      {showOptions && 
        <Listbox 
          hideOptions={() => setShowOptions(false)} 
          listWidth={listWidth} 
          options={options}
          labelId={labelId}
          selectId={instanceId}
          buttonId={buttonId}
          selections={selections}
          updateSelections={updateSelections}
          toggleShowOptions={toggleShowOptions}
          isListWrapped={isListWrapped}
          selections={selections}
          updateSelections={updateSelections}
          />
      }
    </div>
  )
  
  function updateSelections(selectedItems) {
    setSelections(selectedItems)
    optionsSelected(selectedItems)
  }

  function toggleShowOptions() {
    setShowOptions(prevShow => {
        if (prevShow === true) {
          const button = document.getElementById(buttonId)
          button && button.focus()
        }
        return !prevShow
    })
  }
  
  function keyDown(evt) {
    if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') {
      evt.preventDefault();
      setShowOptions(true);
    } else if (evt.key === 'Enter') {
      evt.preventDefault();
      toggleShowOptions();
    }
  }
}

function useRequiredPropCheck(requiredProps) {
  const [missingRequiredPropError, setMissingRequiredPropError] = useState(null)
  useEffect(() => {
    let timeout
    let missingProps = []
    requiredProps.map(requirement => {
      Object.values(requirement)[0] === undefined ? missingProps.push(Object.keys(requirement)[0]) : null
    })
    
    const message = (getErrorMessage(missingProps))
    if (message) {
      timeout = setTimeout(() => {
        console.error(message)          
      }, 1000)
      setMissingRequiredPropError(true)
    } else {
      setMissingRequiredPropError(false)
    }
    return () => {
      window.clearTimeout(timeout)
    }
  }, [requiredProps])
  
  return missingRequiredPropError
}

function getErrorMessage(missingProps) {
  if (missingProps.includes("ariaLabelText") && missingProps.includes("labelText")) {
    return 'ERROR: CprShortSingleSelect expects prop "labelText" or "ariaLabelText". If a hidden label is desired, please provide prop "ariaLabelText".'
  } else if (!missingProps.includes("ariaLabelText") && !missingProps.includes("labelText")) {
    return 'ERROR: CprShortSingleSelect props "labelText" and "ariaLabelText" are mutually exclusive (both cannot be provided at once).'
  } else {
    const filteredMissingProps = (missingProps.includes('ariaLabelText') || missingProps.includes('labelText')) ? missingProps.filter(prop => prop !== 'ariaLabelText' && prop !== 'labelText') : null
    return filteredMissingProps.length ? 
    `ERROR: CprShortMultiSelect cannot be rendered: missing required prop(s): ${filteredMissingProps.toString().split(',').join(', ')}`
    : null
  }
}

const customElement = reactToCustomElement(CpsShortMultiSelect, {parentClass: HTMLElement, properties: [
  'labelText',
  'ariaLabelText',
  'placeholderText',
  'options',
  'initialSelection',
  'optionsSelected',
  'value',
  'isDisabled',
  'width',
  'listWidth',
  'isListWrapped'
]})

customElements.define('cps-short-multi-select', customElement)
export const CprShortMultiSelect = customElementToReact({name: 'cps-short-multi-select'})

const styles = k`
  button.button, button.button:focus, button.button:hover {
    border-radius: 5px;
    border: 1px solid var(--cps-color-athens);
    min-width: 152px;
    max-width: 100%;
    height: 32px;
    background-color: white;
    padding: 0 8px 0 10px;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  button.button:focus {
    box-shadow: 0 0 3px 0 var(--cps-color-primary);
  }

  button:disabled, button:disabled:hover {
    border: 1px dashed var(--cps-color-athens);
    color: var(--cps-color-af);
    background-color: var(--cps-color-ash)
  }

  .button.unselected, .button.optionsOpen {
    color: var(--cps-color-af);
  }

  .caret {
    flex-grow: 0;
    display: flex;
    align-items: center;
  }

  .hidden {
    display: none;
  }

  .label {
    display: block;
    padding-bottom: 4px;
  }

  .noOverflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .select {
    position: relative;
  }
`;