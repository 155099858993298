import React from 'react';
import { CpCheckboxGroup } from "@components";
import { useController } from 'react-hook-form';
import { any, object, string } from 'prop-types';

export function CpCheckboxGroupField(props) {
  const {
    fieldName,
    control,
    required,
    rules,
    ...cpToggleProps
  } = props;
  const {
    field: { value, onBlur, onChange },
  } = useController({ name: fieldName, control, rules });

  return (
    <CpCheckboxGroup
      {...cpToggleProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

CpCheckboxGroupField.Item = CpCheckboxGroup.Item;

CpCheckboxGroupField.propTypes = {
  control: any.isRequired,
  fieldName: string.isRequired,
  rules: object,
};
