import React, { useMemo, forwardRef } from 'react';
import {
  AsYouType,
  parseIncompletePhoneNumber,
  isPossiblePhoneNumber,
} from 'libphonenumber-js';
import { CpInput } from '@components';
import { object, string, bool } from 'prop-types';
import { useController } from 'react-hook-form';
import { mergeRefs } from '@helpers';

export const CpInputFieldPhoneNumber = forwardRef(
  function CpInputFieldPhoneNumber(props, ref) {
    const {
      control,
      fieldName,
      rules = {},
      required,
      options = {},
      ...inputProps
    } = props;
    const { defaultCountry = 'US' } = options;

    const mergedRules = useMemo(() => {
      let mRules = {
        validate: (v) => {
          const message = 'Field must be valid phone number';
          if (typeof v === 'string' && v.length < 3) {
            return message;
          }
          const val = new AsYouType(defaultCountry);
          val.input(v);
          const isPossible = isPossiblePhoneNumber(
            val.getNumber?.()?.number || val.formattedOutput
          );
          return isPossible || message;
        },
      };
      if (required) {
        mRules.required = 'Field is required';
      }
      return { ...mRules, ...rules };
    }, [JSON.stringify(rules), required]);

    const {
      field: { value, onChange, onBlur, ref: fieldRef },
      fieldState: { error },
    } = useController({ control, name: fieldName, rules: mergedRules });

    const formattedPhone = useMemo(() => {
      return new AsYouType(defaultCountry).input(value);
    }, [value]);

    function updatePhone(val) {
      if (val.length > 20) return;

      let newValue = parseIncompletePhoneNumber(val);
      if (val.length < formattedPhone.length) {
        if (newValue.indexOf(value) === 0) {
          newValue = newValue.slice(0, -1);
        }
      }

      onChange(newValue);
    }

    return (
      <CpInput
        value={formattedPhone}
        onChange={updatePhone}
        onBlur={onBlur}
        error={error?.message}
        required={required}
        ref={mergeRefs(ref, fieldRef)}
        {...inputProps}
      />
    );
  }
);

CpInputFieldPhoneNumber.propTypes = {
  control: object.isRequired,
  fieldName: string.isRequired,
  rules: object,
  required: bool,
  options: object,
};
