import React, { forwardRef } from 'react';
import { useCss, a } from 'kremling';
import { bool, object, oneOfType, string } from 'prop-types';
import { noop } from 'lodash'

export const CpArea = forwardRef(function CpArea(props, ref) {
  const {
    children,
    className,
    disabled,
    focusInset,
    onKeyDown,
    onClick,
    ...rest
  } = props;
  const scope = useCss(css);

  function keyDownHandler(e) {
    if (onKeyDown) onKeyDown(e);
    if ((e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar')) {
      e.preventDefault();
      e.target.click();
    }
  }

  return (
    <div
      {...scope}
      className={a('cp-area')
        .a(className)
        .m('cp-area--focus-inset', focusInset)
      }
      tabIndex={disabled ? -1 : 0}
      role="button"
      aria-disabled={disabled}
      disabled={disabled}
      onKeyPress={keyDownHandler}
      ref={ref}
      onClick={disabled ? noop : onClick}
      {...rest}
    >
      {children}
    </div>
  );
});

CpArea.propTypes = {
  className: oneOfType([string, object]),
  disabled: bool,
  focusInset: bool,
};

const css = `
  .cp-area {
    cursor: pointer;
    position: relative;
    background-color: var(--cp-color-area-bg);
    display: block;
    color: var(--cp-color-primary-text);
    text-decoration: none;
    transition: box-shadow var(--cp-form-transition-duration) ease;
  }

  .cp-area:not([aria-disabled=true]):hover {
    background-color: var(--cp-color-area-hover-bg);
  }

  .cp-area:focus {
    box-shadow: var(--cp-form-focus-state);
    outline: none;
    z-index: 1;
  }

  .cp-area.cp-area--focus-inset:focus {
    box-shadow: var(--cp-form-focus-state-inset);
  }

  .cp-area[aria-disabled=true] {
    cursor: default;
    box-shadow: none;
  }
`;
