import React, { forwardRef } from 'react';
import { useCss, k, a } from 'kremling';
import { string, bool, func } from 'prop-types';

export const CpToggle = forwardRef(function CpToggle(props, ref) {
  const { checked, onChange, onBlur, disabled, id, className = "" } = props;
  const scope = useCss(css);

  return (
    <div {...scope} className={`${className} toggle-box`} ref={ref}>
      <input
        id={id}
        checked={checked}
        aria-disabled={disabled}
        className={a('toggle').m('disabled', disabled)}
        type="checkbox"
        onBlur={onBlur}
        onChange={e => {
          if (disabled) return e.preventDefault();
          onChange(e.target.checked);
        }}
        onClick={e => {
          if (disabled) e.preventDefault();
        }}
        onFocus={e => {
          if (disabled) e.preventDefault();
        }}
        onMouseDown={e => {
          if (disabled) e.preventDefault();
        }}
      />
      <span />
    </div>
  )
});

CpToggle.propTypes = {
  checked: bool,
  onBlur: func,
  onChange: func,
  disabled: bool,
  className: string,
  id: string,
}

const css = k`
  .toggle-box {
    position: relative;
    display: inline-block;
  }

  .toggle {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 75%;
    cursor: pointer;
    z-index: 1;
    margin: 0;
  }

  .toggle + span {
    font-size: 1.6rem;
    position: relative;
    padding-left: 4rem;
    top: -.4rem;
  }

  .toggle + span:before {
    content: " ";
    display: table-cell;
    position: absolute;
    border-radius: 8rem;
    border: 0;
    width: 4rem;
    left: 0;
    height: 1.6rem;
    /*CHANGE THIS COLOR NAME LATER*/
    background-color: var(--cps-color-nav-label);
    transition: .15s ease-in background-color;
    top: .6rem;
    visibility: visible;
  }

  .toggle + span:after {
    content: " ";
    display: table-cell;
    position: absolute;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 1.2rem;
    /*CHANGE THIS COLOR NAME LATER*/
    background-color: var(--cps-color-card-background);
    border: .1rem solid var(--cps-color-border);
    box-shadow: 0 .1rem .4rem 0 rgba(0, 0, 0, 0.26);
    top: .2rem;
    left: 0;
    visibility: visible;
    transition: .15s ease-in left;
  }

  .toggle:checked + span:before {
    /*CHANGE THIS COLOR NAME LATER*/
    background-color: var(--cps-color-green);
    border: 0;
  }

  .toggle:checked + span:after {
    left: 1.6rem;
  }

  .toggle.disabled {
    cursor: not-allowed;
  }

  .toggle.disabled + span {
    cursor: not-allowed;
  }

  .toggle.disabled + span:before {
    /*CHANGE THIS COLOR NAME LATER*/
    background-color: var(--cps-color-hr);
  }

  .toggle.disabled + span:after {
    box-shadow: none;
  }

  .toggle.disabled:checked + span:before {
    /*CHANGE THIS COLOR NAME LATER*/
    background-color: var(--cps-color-pill-team-bg);
  }

  .toggle:focus + span:after {
    /*CHANGE THIS COLOR NAME LATER*/
    box-shadow: 0 0 0 .4rem rgba(var(--cps-color-pill-text-rgb), 0.2);
  }
`;
