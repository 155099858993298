import React from 'react'
import styles from './cps-icon.styles.css';

import { customElementToReact } from '../custom-element-to-react.js';
import { reactToCustomElement } from '../react-to-custom-element.js';
import { iconMigrateList } from './icon-migrate';

export default class CpsIcon extends React.Component {
  render() {
    const { name, size = 24 } = this.props;
    const icon = iconMigrateList[name] || name;
    return (
      <svg
        className={`${styles.icon} ${icon}`}
        style={{ width: `${size}px`, height: `${size}px` }}
        viewBox="0 0 24 24"
      >
        <use href={`#${icon}`} xlinkHref={`#${icon}`} />
      </svg>
    );
  }
}

const elementName = 'cps-icon';
const customElement = reactToCustomElement(CpsIcon, { parentClass: HTMLElement, properties: ['name', 'size'] });

customElements.define(elementName, customElement);
export const CprIcon = customElementToReact({ name: elementName });
