import React, { useMemo } from 'react';
import { escapeRegExp } from 'lodash';

/**
 * Use Highlighter
 * @param {string} text
 * @param {string} search
 * @param {object} overrideStyle
 * @returns {function}
 */
export const useHighlighter = (
  text = '',
  search,
  overrideStyle = {
    color: 'var(--cp-color-app-primary)',
    backgroundColor: 'transparent',
  }
) => {
  return useMemo(() => {
    let sanitizedHighlight = search?.toLowerCase().trim()
    if (!search ||
      search.trim().length === 0 ||
      !text.toLowerCase().includes(sanitizedHighlight)) {
      return <span>{text}</span>;
    }
    const sections = text
      .replace(new RegExp(escapeRegExp(search), 'ig'), "*$&*")
      .split('*')

    return (
      <span>
        {sections.map((segment, index) => {
          if (sanitizedHighlight === segment.toLowerCase()) {
            return (<strong style={overrideStyle} key={index}>{segment}</strong>)
          } else return (<span key={index}>{segment}</span>)
        })}
      </span>
    );
  }, [search, text]);
}
