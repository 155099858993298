// intent for using mouse
document.addEventListener('click', () => {
  if (!document.body.classList.contains('intent-mouse')) {
    document.body.classList.add('intent-mouse');
  }
});
document.addEventListener('keydown', () => {
  if (document.body.classList.contains('intent-mouse')) {
    document.body.classList.remove('intent-mouse');
  }
});
