export function currency(amount, options) {
  const {
    type = 'USD',
    locale
  } = options || {};

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: type
  }).format(amount);
}
