import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';

export function ItemBase({ isSelected, id, children, subHeader, ...rest }) {
  function stop(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  if (subHeader) {
    return <div className="item-single item-single--header">{children}</div>;
  }
  return (
    <div
      onMouseDown={stop}
      onMouseUp={stop}
      data-selected={isSelected}
      data-id={id}
      {...rest}
    >
      {children}
    </div>
  );
}

ItemBase.propTypes = {
  isSelected: bool,
  id: oneOfType([string, number]),
};
