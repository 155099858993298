import React, { forwardRef, useRef, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { useCss, k, a } from 'kremling';
import { bool, node, number, oneOfType, string } from 'prop-types';
import { deprecatedOneOf } from '../../prop-types/deprecated-one-of';

export const CpTooltip = forwardRef(({ children, delay = 200, disabled, interactive = false, position = 'bottom', text }, ref) => {
  const scope = useCss(css);
  const [show, setShow] = useState(false);
  const unmountTimeoutRef = useRef();

  // remove once migration is complete
  const childIsFunction = typeof children.type === 'function';
  const placement = placementConversion(position);

  // prevents tooltip from staying open when tabbing through a page when interactive mode is enabled
  const hideOnPopperBlur = {
    name: 'hideOnPopperBlur',
    defaultValue: true,
    fn(instance) {
      return {
        onCreate() {
          instance.popper.addEventListener('focusout', event => {
            if (
              instance.props.hideOnPopperBlur &&
              event.relatedTarget &&
              !instance.popper.contains(event.relatedTarget)
            ) {
              instance.hide();
            }
          });
        }
      };
    }
  };

  function onMount() {
    if (unmountTimeoutRef.current) {
      clearTimeout(unmountTimeoutRef.current);
    }
    setShow(true);
  }

  function onHide({ unmount }) {
    setShow(false);
    unmountTimeoutRef.current = setTimeout(unmount, 200);
  }

  if (disabled) return children;

  return (
    <Tippy
      allowHTML
      animation={true}
      onHide={onHide}
      onMount={onMount}
      maxWidth={240}
      delay={delay ? [delay, interactive ? 500 : 0] : null}
      {...placement}
      zIndex={100001}
      ref={ref}
      render={attrs => {
        return (
          <div
            {...attrs}
            {...scope}
            className={a('cp-tooltip').m('cp-tooltip--show', show)}
            aria-expanded={show}
            tabIndex="-1"
          >
            {text}
            <div className="cp-tooltip-arrow" data-popper-arrow="" />
          </div>
        )
      }
      }
      interactive={interactive}
      plugins={[hideOnPopperBlur]}
    >
      {childIsFunction ? (
        <div style={{ display: 'inline-block' }}>
          {children}
        </div>
      ) : children}
    </Tippy>
  )
});

CpTooltip.propTypes = {
  children: oneOfType([string, node]).isRequired,
  delay: number,
  disabled: bool,
  interactive: bool,
  position: deprecatedOneOf(
    ['top','top-start','top-end','right','right-start','right-end','bottom','bottom-start','bottom-end','left','left-start','left-end','auto','auto-start','auto-end',],
    ['top-left','top-right','left-top','right-top','left-bottom','right-bottom','bottom-left','bottom-right']
  ),
  text: oneOfType([string, node]).isRequired,
};

const css = k`
  .cp-tooltip {
    font-size: 1.2rem;
    background: var(--cp-color-tooltip-bg);
    color: var(--cp-color-tooltip-text);
    padding: .5rem 1rem;
    border-radius: var(--cp-form-border-radius);
    font-weight: 500;
    max-width: 28rem;
    opacity: 0;
    transition: opacity 200ms ease;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  .cp-tooltip--show {
    opacity: 1;
  }
  /* The max-width should match the phone-only media query in client-portal */
  @media screen and (max-width: 599px) {
    .cp-tooltip--show{
      opacity: 0;
    }
  }

  .cp-tooltip-arrow,
  .cp-tooltip-arrow::before {
    position: absolute;
    width: .8rem;
    height: .8rem;
    background: inherit;
  }

  .cp-tooltip-arrow {
    visibility: hidden;
  }

  .cp-tooltip-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }


  .cp-tooltip[data-placement^='top'] > .cp-tooltip-arrow {
    bottom: -.4rem;
  }

  .cp-tooltip[data-placement^='bottom'] > .cp-tooltip-arrow {
    top: -.4rem;
  }

  .cp-tooltip[data-placement^='left'] > .cp-tooltip-arrow {
    right: -.4rem;
  }

  .cp-tooltip[data-placement^='right'] > .cp-tooltip-arrow {
    left: -.4rem;
  }
`;

// we can get rid of this once we fully migrate away from the old positioning
function placementConversion(position) {
  let placement;
  switch (position) {
    case 'top-left':
      placement = 'top-start';
      break;
    case 'top-right':
      placement = 'top-end';
      break;
    case 'left-top':
      placement = 'left-start';
      break;
    case 'left-bottom':
      placement = 'left-end';
      break;
    case 'right-top':
      placement = 'right-start';
      break;
    case 'right-bottom':
      placement = 'right-end';
      break;
    case 'bottom-left':
      placement = 'bottom-start';
      break;
    case 'bottom-right':
      placement = 'bottom-end';
      break;
    default:
      placement = position;
  }
  return { placement };
}
