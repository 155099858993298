import React, { useEffect, useRef, useState } from 'react';
import { object, func } from 'prop-types';

import { CpSelect } from '../cp-select/cp-select.component';
import { CpInput } from '@components';

export function CpTime({ contentWidth, disabled, onChange, position, value }) {
  const [search, setSearch] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = value?.formattedTime || '';
    }
  }, []);

  function onInputChange(searchOnChange) {
    const inputVal = inputRef?.current?.value || '';
    searchOnChange(inputVal);
  }

  function selectOnChange(val) {
    if (inputRef?.current) {
      inputRef.current.value = val?.formattedTime || '';
    }
    onChange(val);
  }

  function settle() {
    const inputVal = inputRef?.current?.value;
    const newVal = inputVal !== value?.formattedTime;
    if (!newVal || !inputVal) return;
    selectOnChange(buildTime(inputVal));
  }

  function selectAll() {
    if (inputRef?.current) {
      inputRef.current.select();
    }
  }

  return (
    <CpSelect
      contentWidth={contentWidth}
      data={timeList}
      disabled={disabled}
      position={position}
      onChange={selectOnChange}
      renderItem={CpSelect.ItemSingle}
      renderTrigger={({ open, searchOnChange, close }) => (
        <CpInput
          onClick={open}
          onChange={() => onInputChange(searchOnChange)}
          placeholder="Select time"
          autoFocus
          onFocus={selectAll}
          onBlur={settle}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              close();
              settle();
            }
          }}
          disabled={disabled}
          ref={inputRef}
        />
      )}
      searchFilter={CpSelect.filterAlpha(search)}
      searchHideEmptyState
      searchOnChange={setSearch}
      searchValue={search}
      transformData={(item) => ({
        id: item.formattedTime,
        name: item.formattedTime,
      })}
      value={value}
      clearable={true}
    />
  );
}

CpTime.propTypes = {
  onChange: func.isRequired,
  value: object,
};

const regexHourMinute = /([0-9]?[0-9]):([0-9]?[0-9])(am|pm)?/i;
const regexHour = /([0-9]?[0-9])(am|pm)?/i;

const hours = Array.from(Array(24), (_, i) => i);
const minutes = [0, 30];

export const timeList = hours.reduce((acc, hour) => {
  return [...acc, ...minutes.map(minute => ({ hour, minute, formattedTime: formatTime(hour, minute) }))];
}, []);

function formatTime(hour, minute) {
  let hr, mer;
  if (hour === 0) hr = 12;
  else if (hour > 0 && hour < 12) hr = hour;
  else if (hour > 12) hr = hour - 12;
  else hr = hour;
  mer = hour > 11 ? 'pm' : 'am';
  return `${hr}:${minute.toString().padStart(2, '0')}${mer}`;
}

function buildTime(str) {
  if (!str) return;
  let _, hour, minute, meridiem;
  const hourMinuteResult = str.match(regexHourMinute);
  const hourResult = str.match(regexHour);
  if (hourMinuteResult) {
    [_, hour, minute, meridiem] = hourMinuteResult;
  } else if (hourResult) {
    [_, hour, meridiem] = hourResult;
  }

  if (!hour && !minute && !meridiem) return null;

  let hourNumber = parseInt(hour);
  let minuteNumber = parseInt(minute);

  if (!minuteNumber) {
    minuteNumber = 0;
  } else if (minute[0] !== '0' && minuteNumber < 6) {
    minuteNumber = minuteNumber * 10;
  } else if (minuteNumber > 59) {
    minuteNumber = 59;
  }

  if (!hourNumber) {
    hourNumber = 0;
  } else if (hourNumber > 23) {
    hourNumber = 23;
  } else if (hourNumber === 12 && meridiem === 'am') {
    hourNumber = 0;
  } else if (hourNumber < 12 && meridiem === 'pm') {
    hourNumber = hourNumber + 12;
  }
  return { hour: hourNumber, minute: minuteNumber, formattedTime: formatTime(hourNumber, minuteNumber)};
}