import React from 'react';
import { string, node } from 'prop-types';
import { useCss, a } from 'kremling';

export function CpEmptyState({ className, cta, img, subText, text, ...rest }) {
  const scope = useCss(css);
  const iconSource = getIconSource(img);
  return (
    <div
      {...scope}
      className={a('cp-empty-state').a(className)}
      {...rest}
    >
      <div className="cp-empty-state__inner">
        <div
          style={{ backgroundImage: `url(${iconSource})` }}
          alt={img}
          className="cp-empty-state__img"
        />
        {text && (
          <div className="cp-empty-state__text">
            {text}
          </div>
        )}
        {subText && (
          <div className="cp-empty-state__subText">
            {subText}
          </div>
        )}
        {!!cta && (
          <div className="cp-empty-state__cta">
            {cta}
          </div>
        )}
      </div>
    </div>
  );
}

function getIconSource(img) {
  return hasImageExtension(img)
    ? img
    : `https://cdn.canopytax.com/static/empty-states/${encodeURIComponent(img)}.svg`;
}

function hasImageExtension(img) {
  return Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(img.toString()));
}

CpEmptyState.propTypes = {
  cta: node,
  img: string.isRequired,
  subText: string,
  text: string,
}

const css = `
  .cp-empty-state {
    max-width: 40rem;
    margin: 0 auto;
  }
  
  .cp-empty-state__inner {
    text-align: center;
  }
  
  .cp-empty-state__img {
    width: 8rem;
    height: 8rem;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .cp-empty-state__text {
    font-size: 1.4rem;
    font-weight: 700;
  }
  
  .cp-empty-state__subText {
    padding-top: .4rem;
    color: var(--cps-color-cool-gray);
  }
  
  .cp-empty-state__cta {
    padding-top: 1.2rem;
  }
  
`;