// key: old name
// value: new name
export const iconMigrateList = {
  '100-percent-zoom': 'preview-100-percent',
  'fit-to-one-page-zoom': 'preview-fit-to-page',
  'fit-to-width-zoom': 'preview-fit-to-width',
  'zoom-in': 'preview-zoom-in',
  'zoom-out': 'preview-zoom-out',
  'left-aligned': 'rte-left-aligned',
  'center-aligned': 'rte-center-aligned',
  'right-aligned': 'rte-right-aligned',
  'header1': 'rte-h1',
  'header2': 'rte-h2',
  'header3': 'rte-h3',
  'italics': 'rte-italic',
  'underline': 'rte-underline',
  'bold': 'rte-bold',
  'list': 'rte-list-unordered',
  'number': 'rte-list-ordered',
  'folder': 'folder',
  'folder-open': 'folder-open',
  'client-portal-folder': 'folder-person',
  'upload-folder': 'folder-up',
  'move': 'folder-right',
  'new-folder': 'folder-add',
  'new-folder-template': 'folder-add-template',
  'zip-folder': 'folder-zip',
  'files': 'file',
  'forms': 'file-document',
  'word-doc-file': 'file-document',
  'code-file': 'file-code',
  'audio-file': 'file-audio',
  'presentation-file': 'file-presentation',
  'video-file': 'file-video',
  'spreadsheet-file': 'file-spreadsheet',
  'image-file': 'file-image',
  'pdf-file': 'file-pdf',
  'move-file': 'file-right',
  'web-link-file': 'file-link',
  'zip-file': 'file-zip',
  'statements': 'file-statement',

  'add': 'add-large',
  'add-neg': 'add-circle-filled',
  'quick-add': 'add-circle-open',
  'add-sm': 'add-small',

  'close': 'close-large',
  'close-sm': 'close-small',
  'sm-close': 'close-circle-open',
  'sm-neg': 'close-circle-filled',

  'error': 'alert-triangle-open-large',
  'sm-error': 'alert-triangle-filled-small',
  'error-neg-lg': 'alert-triangle-filled-large',
  'exclamation': 'alert-exclamation',

  'sm-caret-down': 'caret-small-down',
  'caret': 'caret-small-down',
  'sm-caret-left': 'caret-small-left',
  'sm-caret-right': 'caret-small-right',
  'folder-open-caret': 'caret-small-right',
  'folder-caret': 'caret-small-right',
  'sm-caret-up': 'caret-small-up',
  'up-caret': 'caret-large-up',
  'right-caret': 'caret-large-right',
  'left-caret': 'caret-large-left',
  'down-caret': 'caret-large-down',

  'arrow-left': 'arrow-line-left',
  'forward': 'arrow-open-right',
  'move-up': 'arrow-open-up',
  'move-down': 'arrow-open-down',

  'transfer': 'af-transfer',
  'collapse': 'af-open-collapse',
  'expand': 'af-open-expand',
  'redo': 'af-redo',
  'undo': 'af-undo',
  'refresh': 'af-refresh',
  'reply': 'af-reply',
  'sm-retry': 'af-retry-small',
  'lg-retry': 'af-retry-large',
  'download': 'af-open-down',
  'reorder': 'af-reorder',
  'export': 'af-line-square-up',
  'external-link': 'af-external-link',
  'merge': 'af-merge',
  'sort': 'af-sort',

  'help': 'help-circle-open-large',
  'help-sm': 'help-circle-open-small',

  'lg-check': 'checkmark-large',
  'license': 'checkmark-line-badge',
  'projects': 'checkmark-line-square',
  'tasks': 'checkmark-line-square',
  'valid': 'checkmark-circle-open-large',
  'check-lg-neg': 'checkmark-circle-filled-large',
  'sm-check': 'checkmark-small',

  'information': 'information-circle-open-large',
  'sm-information': 'information-circle-open-small',
  'information-neg': 'information-circle-filled-small',

  'remove-lg': 'remove-circle-open-large',
  'remove-sm': 'remove-circle-open-small',
  'remove-neg': 'remove-circle-filled-small',
  'indeterminate': 'remove-minus',

  'add-person': 'person-add',
  'switch-account': 'person-switch-account',
  'client': 'person',
  'visibility-inactive': 'person',
  'client-access': 'person-key',
  'client-no-access': 'person-lock',
  'clients': 'person-people',
  'contacts': 'person-details',
  'visibility-active': 'person-checkmark',
  'share': 'person-arrow-right',

  'branding': 'shape-star-open',
  'favorites-hollow': 'shape-star-open',
  'favorites': 'shape-star-filled',
  'fast': 'shape-lightning-bolt',
  'circle': 'shape-circle-open',
  'cloud': 'shape-cloud-open',
  'dot-file': 'shape-dot-filled',
  'status': 'shape-circle-dashed',
  'play': 'shape-triangle-right-open',
  'pause': 'shape-rectangles',

  'email': 'communication-envelope',
  'message-remove': 'communication-envelope-remove',
  'phone': 'communication-phone',
  'send': 'communication-paper-airplane',
  'megaphone': 'communication-megaphone',
  'conversation': 'communication-chat-bubble',
  'notice': 'communication-letter-front',
  'letters': 'communication-letter-back',
  'survey': 'communication-clipboard',
  'survey-override': 'communication-clipboard-x',
  'sign': 'communication-signature-pen',
  'signature': 'communication-signature',
  'email-template': 'communication-email-template',
  'initial': 'communication-initials',
  'inbox': 'communication-inbox',

  'billing': 'billing-square-open',
  'cash': 'billing-circle-open',
  'payments': 'billing-credit-card',
  'credits': 'billing-circle-open-dash',
  'invoices': 'billing-invoice',

  'template': 'organize-template',
  'boards': 'organize-boards',
  'tile-view': 'organize-tiles',
  'tag': 'organize-tag',
  'sidebar': 'organize-sidebar',
  'grid-view': 'organize-grid',

  'archive': 'crud-archive',
  'save-to-files': 'crud-floppy-disk',
  'duplicate': 'crud-duplicate',
  'trash': 'crud-trash-large',
  'edit': 'crud-pencil',
  'notes': 'crud-pencil',

  'alarm': 'misc-alarm-clock',
  'menu-2': 'misc-menu',
  'nav': 'misc-menu',
  'attachment': 'misc-paperclip',
  'bell': 'misc-call-bell',
  'upload': 'misc-cloud-up-arrow',
  'visible': 'misc-open-eye',
  'hidden': 'misc-closed-eye',
  'business': 'misc-buildings',
  'calendar': 'misc-calendar',
  'camera': 'misc-camera',
  'cog': 'misc-gear',
  'color-picker': 'misc-dropper',
  'palette': 'misc-dropper',
  'compass': 'misc-compass',
  'details': 'misc-four-lines',
  'drag': 'misc-drag-handle',
  'notes-2': 'misc-sticky-note',
  'table': 'misc-table',
  'taxes': 'misc-document-calculator',
  'work': 'misc-briefcase',
  'filter': 'misc-funnel',
  'flag': 'misc-flag',
  'more': 'misc-kabob',
  'home': 'misc-house',
  'image': 'misc-mountains-large',
  'irs': 'misc-scales',
  'lock': 'misc-padlock',
  'notifications': 'misc-bell',
  'sm-pending': 'misc-ellipsis',
  'source': 'misc-crosshairs',
  'pin': 'misc-push-pin',
  'print': 'misc-printer',
  'recent': 'misc-clock',
  'time-entry': 'misc-timer',
  'reports': 'misc-bar-graph',
  'restore-file': 'misc-clock-back',
  'search': 'misc-magnifying-glass',
  'subtask': 'misc-subtask',
  'diagnostics': 'misc-cardiogram',
  'calculator': 'misc-calculator',
  'calculator-on': 'misc-bright-calculator',
  'items': 'misc-barcode',
  'quicklook': 'misc-binoculars',
  'paintbrush': 'misc-paintbrush',
  'link': 'misc-link',
}