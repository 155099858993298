import React from 'react';
import styles from './cps-datepicker.styles.css';
import moment from 'moment';
import { range, chunk, partial } from 'lodash';

const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export default class Days extends React.Component {
  render() {
    const { endDate, startDate, viewDate, selectDate, width } = this.props;

    const square = Math.floor(width / 7);

    return (
      <div>
        <div className={styles.week}>
          {days.map((day, index) => {
            return (
              <div
                style={{
                  minHeight: `${square}px`,
                  minWidth: `${square}px`,
                }}
                key={index}
                className={`${styles.day} ${styles.weekHeaderDay} cps-medium-gray cps-wt-bold`}>
                {day}
              </div>
            );
          })}
        </div>
        {formDayGrid(viewDate).map((week, index) => {
          return (
            <div key={index} className={styles.week}>
              {week.map((day, index) => {
                const date = day.attachedDate;
                const isStartDate = date.format('MMDDYY') === startDate.format('MMDDYY');
                const isEndDate = endDate ? date.format('MMDDYY') === endDate.format('MMDDYY') : false;
                const isSunday = date.format('dddd') === 'Sunday';
                const isSaturday = date.format('dddd') === 'Saturday';
                const isToday = date.isSame(moment(), 'day');
                const isPicked =
                  (startDate && startDate.isSame(date, 'day')) || (endDate && endDate.isSame(date, 'day'));
                const thisMonth = date.month() === viewDate.month();
                const isBetween = endDate && date.isBetween(startDate, endDate, 'day');
                const leftRadius = isStartDate || isSunday ? square / 2 : 0;
                const rightRadius = isEndDate || isSaturday ? square / 2 : 0;

                return (
                  <div
                    key={index}
                    className={`${styles.dayBackground}`}
                    style={{
                      height: `${square}px`,
                      width: `${square}px`,
                      borderRadius: `${square / 2}px`,
                    }}>
                    <div
                      style={{
                        height: `${isBetween || isStartDate || isEndDate ? square - 12 : square}px`,
                        borderRadius: `${leftRadius}px ${rightRadius}px ${rightRadius}px ${leftRadius}px`,
                      }}
                      className={getBackgroundDayClass(isPicked, isStartDate, isEndDate, isBetween)}>
                      <div
                        style={{
                          minHeight: `${square}px`,
                          minWidth: `${square}px`,
                          borderRadius: `${square / 2}px`,
                        }}
                        onClick={partial(selectDate, date)}
                        className={getDayClass(isPicked, isStartDate, isEndDate, isToday, isBetween, thisMonth)}>
                        {day.day}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

function getDayClass(isPicked, isStartDate, isEndDate, isToday, isBetween, thisMonth) {
  let classes = `${styles.day} `;

  if (isPicked || isStartDate || isEndDate) {
    classes += `${styles.picked}`;
  } else if (isToday) {
    classes += `${styles.today} cps-wt-bold`;
  } else if (isBetween) {
    classes += `cps-wt-regular`;
  } else if (thisMonth) {
    classes += 'cps-light-gray';
  } else {
    classes += 'cps-gray-10';
  }

  return classes;
}

function getBackgroundDayClass(isPicked, isStartDate, isEndDate, isBetween) {
  let classes = `${styles.day} `;
  if (isPicked || isStartDate || isEndDate || isBetween) {
    classes += `cps-bg-background`;
  }
  return classes;
}

function formDayGrid(date) {
  const newDate = moment(date);
  const daysInMonth = date.daysInMonth();
  const weekdayStart = newDate.date(1).weekday();
  const weekdayEnd = newDate.date(daysInMonth).weekday();
  const daysInLastMonth = newDate.month(date.month() - 1).daysInMonth();

  const days = [
    ...range(daysInLastMonth - (weekdayStart - 1), daysInLastMonth + 1),
    ...range(1, daysInMonth + 1),
    ...range(1, 6 - weekdayEnd + 1),
  ].map((day, index) => {
    if (index < weekdayStart) {
      const attachedDate = moment();
      attachedDate.year(date.year());
      attachedDate.month(date.month() - 1);
      attachedDate.date(day);
      return { day, attachedDate };
    }
    if (index >= daysInMonth + weekdayStart) {
      const attachedDate = moment();
      attachedDate.year(date.year());
      attachedDate.month(date.month() + 1);
      attachedDate.date(day);
      return { day, attachedDate };
    } else {
      const attachedDate = moment();
      attachedDate.year(date.year());
      attachedDate.month(date.month());
      attachedDate.date(day);
      return { day, attachedDate };
    }
  });

  return chunk(days, 7);
}
