import React from 'react';

import { CpButton } from '@components';

export function Footer({ close, value, clearAll }) {
  return (
    <>
      <CpButton btnType="flat" onClick={close} type="button">
        Done
      </CpButton>
      {!!value.length && (
        <CpButton btnType="tertiary" onClick={clearAll} type="button">
          Clear all
        </CpButton>
      )}
    </>
  );
}

Footer.propTypes = {};
