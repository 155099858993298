import React from 'react';
import styles from './cps-datepicker.styles.css';
import { customElementToReact } from '../custom-element-to-react.js';
import { reactToCustomElement } from '../react-to-custom-element.js';
import moment from 'moment';
import { noop, partial } from 'lodash';
import { granularityOptions, modeOptions } from './cps-datepicker.helpers.js';
import Header from './header.component.js';
import Days from './days.component.js';
import FourByThree from './four-by-three.component.js';

export default class CpsCalendar extends React.Component {
  static defaultProps = {
    date: moment(),
    removeDateText: 'Remove date',
    selectDate: noop,
    width: 264,
  };

  constructor(props) {
    super(props);

    this.state = {
      granularity: granularityOptions.DAY,
      viewDate: props.date ? moment(props.date) : moment(),
    };
  }

  componentDidUpdate(prevProps) {
    const format = 'MM-DD-YYYY';
    if (this.props.endDate && moment(this.props.endDate).format(format) !== moment(prevProps.endDate).format(format)) {
      this.setState({ viewDate: moment(this.props.endDate) });
    } else if (this.props.date && moment(this.props.date).format(format) !== moment(prevProps.date).format(format)) {
      this.setState({ viewDate: moment(this.props.date) });
    }
  }

  render() {
    const { endDate, removeDateOption, removeDateText, width } = this.props;
    const { viewDate, granularity } = this.state;

    return (
      <div style={{ width: `${width}px` }} className={styles.datepicker}>
        <div className={styles.datepickerBody}>
          <Header
            changeRange={this.changeRange}
            changeGranularity={this.changeGranularity}
            viewDate={viewDate}
            granularity={granularity}
          />
          {granularity === granularityOptions.DAY ? (
            <Days
              width={width}
              startDate={moment(this.props.date)}
              endDate={endDate}
              viewDate={viewDate}
              selectDate={this.selectDate}
            />
          ) : (
              <FourByThree
                width={width}
                pickRangeChangeDisplay={this.pickRangeChangeDisplay}
                granularity={granularity}
                viewDate={viewDate}
              />
            )}
        </div>
        {removeDateOption && (
          <div
            onClick={partial(this.selectDate, null)}
            className={`${styles.removeDateFooter} cps-color-primary cps-wt-bold`}>
            {removeDateText}
          </div>
        )}
      </div>
    );
  }

  selectDate = date => {
    const { customElement, selectDate } = this.props;

    if (customElement) {
      customElement.dispatchEvent(
        new CustomEvent('datechange', {
          detail: date ? new Date(date) : null,
        })
      );
    } else {
      selectDate(date);
    }
  };

  changeGranularity = granularity => {
    this.setState({
      granularity,
    });
  };

  changeRange = modifier => {
    this.setState(prevState => ({
      viewDate: getRangeDate(prevState.granularity, modifier, prevState.viewDate),
    }));
  };

  pickRangeChangeDisplay = (range, granularity) => {
    const newDate = moment(this.state.viewDate);

    newDate[this.state.granularity === granularityOptions.MONTH ? granularityOptions.MONTH : granularityOptions.YEAR](
      range
    );

    this.setState({
      granularity,
      viewDate: newDate,
    });
  };
}

function getRangeDate(subject, modifier, oldDate) {
  const date = moment(oldDate);

  if (subject === granularityOptions.DAY) return date.month(oldDate.month() + modifier);
  if (subject === granularityOptions.MONTH) return date.year(oldDate.year() + modifier);
  if (subject === granularityOptions.YEAR) return date.year(oldDate.year() + modifier * 10);
  if (subject === granularityOptions.DECADE) return date.year(oldDate.year() + modifier * 100);
}

const cpsCalendarProps = ['date', 'endDate', 'removeDateOption', 'removeDateText', 'width'];
const customElement = reactToCustomElement(CpsCalendar, {
  parentClass: HTMLElement,
  properties: cpsCalendarProps,
});
customElements.define('cps-calendar', customElement);
export const CprCalendar = customElementToReact({ name: 'cps-calendar' });
