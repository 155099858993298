import React, { useState, useMemo, useEffect } from 'react';
import { AsYouType, parseIncompletePhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js';
import { CpInput } from '@components'
import { string, func, bool, object } from 'prop-types'
import { noop } from 'lodash'

PhoneNumber.propTypes = {
  isValid: bool,
  setIsValid: func,
  value: string,
  onChange: func,
  options: object
}

export function PhoneNumber(props) {
  const [isValidDefault, setIsValidDefault] = useState(true)
  const {
    isValid = isValidDefault,
    setIsValid = setIsValidDefault,
    value,
    onChange = noop,
    options = {},
    ...inputProps
  } = props
  const { defaultCountry = 'US' } = options
  const formattedPhone = useMemo(() => new AsYouType(defaultCountry).input(value), [value])

  useEffect(() => {
    if (typeof value === 'string' && value.length < 3) {
      setIsValid(false)
    } else {
      const val = new AsYouType(defaultCountry)
      val.input(value)
      const isPossible = isPossiblePhoneNumber(val.getNumber?.()?.number || val.formattedOutput)
      // val.formattedOutput is used as a fallback if a '+' with an invalid country code is added
      setIsValid(isPossible)
    }
  }, [value])

  function updatePhone(val) {
    if (val.length > 20) return // Too long to be a valid phone number

    let newValue = parseIncompletePhoneNumber(val)
    // By default, this component will parse the value
    // for a fragment of a phone number and re-format it,
    // then save the raw value back on change. Backspace
    // on something like "(123)" would become "(123" which
    // would save as "123" and immediately be reformatted to "(123)"
    // which would prevent the user from being able to backspace.
    // This accounts for those special characters and spaces.
    if (val.length < formattedPhone.length) {
      if (newValue.indexOf(value) === 0) {
        newValue = newValue.slice(0, -1)
      }
    }

    onChange(newValue);
  }

  return (
    <CpInput
      value={formattedPhone}
      onChange={updatePhone}
      error={!isValid && 'Invalid phone number'}
      {...inputProps}
    />
  )
}
