// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cps-icon-styles__icon--9VYWH,\n.cps-icon-styles__icon--9VYWH svg {\n  fill: currentColor;\n}\n\ncps-icon {\n  display: inline-flex;\n  vertical-align: middle;\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-icon/cps-icon.styles.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".icon,\n.icon svg {\n  fill: currentColor;\n}\n\ncps-icon {\n  display: inline-flex;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "cps-icon-styles__icon--9VYWH"
};
export default ___CSS_LOADER_EXPORT___;
