import React from 'react'
import { customElementToReact } from '../custom-element-to-react.js';
import { reactToCustomElement } from '../react-to-custom-element.js';
import styles from './cps-tab.styles.css'
import {navigateToUrl} from 'single-spa'

// Exported only for testing purposes. Shouldn't be used outside of canopy-styleguide project
export class CpsTab extends React.Component {
  static defaultProps = {
    name: "",
    tabIndex: 0,
    icon: "",
    activeTabIndex: 0,
    customClassName: ""
  }

  render() {
    return (
      <div
        className={`${styles.tabContainer} ${this.props.tabIndex === this.props.activeTabIndex ? styles.tabActive : ''} ${this.props.customClassName}`}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyboardClick}
        tabIndex="0"
        role={this.props.href ? 'link' : 'button'}
        href={this.props.href}
      >
        {this.props.icon && <i className={`cps-icon cps-icon-${this.props.icon} cps-margin-right-16`} />}
        {this.props.iconName && <cps-icon name={this.props.iconName} class='cps-margin-right-8' />}
        {this.props.name}
      </div>
    )
  }
  handleKeyboardClick = (e) => {
    if(e.key === 'Enter' || e.key === ' ') {
      this.handleClick()
    }
  }
  handleClick = () => {
    this.props.customElement.dispatchEvent(new CustomEvent('tabclicked', {
      detail: this.props.tabIndex
    }))
    if (this.props.href) {
      navigateToUrl(this.props.href)
    }
  }
}

const customElement = reactToCustomElement(CpsTab, {
  parentClass: HTMLElement,
  properties: ['name', 'iconName', 'icon', 'tabIndex', 'activeTabIndex', 'customClassName', 'href'],
});
customElements.define('cps-tab', customElement);
export const CprTab = customElementToReact({ name: 'cps-tab' });
