import React, { forwardRef } from 'react';
import { node, number, object, oneOfType, string } from 'prop-types';
import { m } from 'kremling';

import { CardHeader } from './components/card-header.component';
import { CardBody } from './components/card-body.component';
import { CardFooter } from './components/card-footer.component';

export const CpCard = forwardRef(function CpCard(props, ref) {
  const {
    children,
    className,
    level,
    ...rest
  } = props;

  return (
    <div
      ref={ref}
      className={
          m('cp-card-l1', !level || level === 1)
          .m('cp-card-l2', level === 2)
          .m('cp-card-l3', level === 3)
          .a(className)
      }
      {...rest}
    >
      {children}
    </div>
  );
});

CpCard.propTypes = {
  children: node,
  level: number,
  className: oneOfType([object, string]),
}

// card components
CpCard.Header = CardHeader;
CpCard.Body = CardBody;
CpCard.Footer = CardFooter;
