import { createContext, useEffect } from 'react';
import { modalService } from '@helpers';

export const durationInMillis = 400;
export const modalWidthMin = 424;
export const modalWidthMax = 832;
export const smallOverlayMax = 1280;

export function forceRange(val, min, max) {
  if (val < min) return min;
  if (val > max) return max;
  return val;
}

export const ModalContext = createContext({});

export const ModalMount = () => {
  useEffect(() => {
    modalService.__openModal();
    return () => modalService.__closeModal();
  }, []);
  return null;
}
