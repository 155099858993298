import React from 'react';
import styles from './cps-datepicker.styles.css';
import { granularityOptions, toShortMonth } from './cps-datepicker.helpers.js';
import { chunk, partial } from 'lodash';

export default class FourByThree extends React.Component {
  render() {
    const { viewDate, granularity, pickRangeChangeDisplay, width } = this.props;
    const square = Math.floor(width / 4);

    return (
      <div
        onClick={e => {
          e.nativeEvent.clickedOnFourByThree = true;
        }}>
        {buildRangeGrid(granularity, viewDate).map((range, rangeIndex) => {
          return (
            <div key={rangeIndex} className={styles.range}>
              {range.map((item, itemIndex) => {
                return (
                  <div
                    style={{
                      minHeight: `${square}px`,
                      minWidth: `${square}px`,
                      borderRadius: `${square / 4}px`,
                    }}
                    key={itemIndex}
                    onClick={partial(
                      pickRangeChangeDisplay,
                      granularity === granularityOptions.MONTH ? itemIndex + rangeIndex * 4 : item,
                      getGranularityChange(granularity)
                    )}
                    className={`${styles.rangeItem} cps-light-gray`}>
                    {item}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

const blocks = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

function buildRangeGrid(granularity, viewDate) {
  if (granularity === granularityOptions.MONTH) {
    return chunk(blocks.map(toShortMonth), 4);
  }

  if (granularity === granularityOptions.YEAR) {
    const decade = Math.floor(viewDate.year() / 10) * 10;
    return chunk(blocks.map(num => num - 1 + decade), 4);
  }

  if (granularity === granularityOptions.DECADE) {
    const century = Math.floor(viewDate.year() / 100) * 100;
    return chunk(blocks.map(num => (num - 1) * 10 + century), 4);
  }
}

function getGranularityChange(granularity) {
  if (granularity === granularityOptions.MONTH) return granularityOptions.DAY;
  if (granularity === granularityOptions.YEAR) return granularityOptions.MONTH;
  if (granularity === granularityOptions.DECADE) return granularityOptions.YEAR;
}
