import React, { useEffect, useState, useRef } from 'react';
import { useCss, a } from 'kremling';
import ReactDOM from 'react-dom';

import { durationInMillis, ModalContext, ModalMount } from './modal.utils';
import { useClickEventStack, useIsMounted, useKeydownEventStack } from '@hooks';

export function ModalContainerComponent(props) {
  const {
    animScale,
    animRotateX,
    children,
    className,
    onAfterClose,
    onAfterOpen,
    onClose,
    show,
    styles = {},
  } = props;
  const scope = useCss(css(animRotateX, animScale));
  const [isVisible, setIsVisible] = useState(show);
  const isMounted = useIsMounted().current;
  const modalRef = useRef()
  useClickEventStack(() => {}, !show);
  useKeydownEventStack({
    keys: ["Escape", "Esc"],
    callback: onClose,
    disabled: !show,
  });

  useEffect(() => {
    // Dont apply focus if the focus is already on one of its children
    const activeElement = document.activeElement
    if (modalRef.current && modalRef.current.contains(activeElement)) return
    // set focus to first focusable element in current modal
    // need to find if there is an element to focus on during open and close in case two modals are open
    const modals = document.querySelectorAll(".cp-modal-container");
    if (modals?.length) {
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex], div.cp-modal';
      const firstFocusableElement = modals[modals.length - 1].querySelectorAll(
        focusableElements
      )[0];
      firstFocusableElement?.focus();
    }
  }, [isVisible]);

  useEffect(() => {
    if (show && !isVisible) {
      setIsVisible(true);
      setTimeout(() => {
        if (isMounted) onAfterOpen?.();
      }, durationInMillis);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      onAfterClose?.();
      setIsVisible(false);
    }
  };

  if (!isVisible) return null;
  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ onClose }}>
      <div
        {...scope}
        className={a('cp-modal-container')
          .m('cp-modal-container--animate-out', !show)
          .m('cp-modal-container--animate-in', show)
        }
        onAnimationEnd={onAnimationEnd}
      >
        <div className="cp-modal-backdrop" />
        <div
          className={a('cp-modal cp-card-l3').a(className)}
          style={styles}
          tabIndex={-1}
          ref={modalRef}
        >
          {children}
        </div>
      </div>
      <ModalMount />
    </ModalContext.Provider>,
    document.body
  );
}

ModalContainerComponent.propTypes = {};

// language=scss
const css = (rotateX, scale) => `
  .cp-modal-container {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100001;
    perspective: 20rem;
    transform: translate(0);
  }

  .cp-modal-backdrop {
    position: absolute;
    inset: 0;
    background-color: rgba(var(--cps-color-primary-text-rgb), .4);
    z-index: 0;
  }

  .cp-modal-container ~ .cp-modal-container .cp-modal-backdrop {
    background-color: rgba(var(--cps-color-primary-text-rgb), .0);
  }

  .cp-modal {
    position: relative;
    background-color: #fff;
    margin: 3.2rem auto;
    z-index: 1;
    box-shadow: var(--cp-box-shadow-l3);
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 3.2rem);
  }

  .cp-modal:focus {
    outline: none;
  }

  .cp-modal__body {
    padding: 1.6rem;
    min-height: 6.4rem;
    overflow: auto;
  }

  .cp-modal-container--animate-in {
      animation: modalFadeIn ${durationInMillis}ms ease;
  }

  .cp-modal-container--animate-in .cp-modal {
    animation: modalTranslateIn ${durationInMillis}ms ease;
  }

  .cp-modal-container--animate-out {
    animation: modalFadeOut ${durationInMillis}ms ease;
  }

  .cp-modal-container--animate-out .cp-modal {
    animation: modalTranslateOut ${durationInMillis}ms ease;
  }


  @keyframes modalFadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes modalFadeOut {
    0% {
      opacity: 1;
    }

    99% {
      opacity: 0;
    }

    100% {
      display: none;
      opacity: 0;
    }
  }

  @keyframes modalTranslateIn {
    0% {
      transform: rotateX(${rotateX}) scale(${scale});
    }

    100% {
      transform: rotateX(0) scale(1);
    }
  }

  @keyframes modalTranslateOut {
    0% {
      transform: rotateX(0) scale(1);
    }

    100% {
      transform: rotateX(${rotateX}) scale(${scale});
    }
  }
`;
