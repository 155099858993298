import React from 'react'
import { customElementToReact } from '../custom-element-to-react.js';
import { reactToCustomElement } from '../react-to-custom-element.js';
import styles from './cps-loader.styles.css';
import { makeDots } from './cps-loader.helper.js';

class CpsLoader extends React.Component {
  static defaultProps = {
    customClass: '',
  }
  render() {
    return (
      <div className={`${styles.loader} ${this.props.page ? styles.page : ''} ${this.props.customClass}`}>
        {makeDots(this.props)}
      </div>
    );
  }
}

const customElement = reactToCustomElement(CpsLoader, {
  parentClass: HTMLElement,
  properties: ['page', 'dotSize', 'color', 'customClass'],
});
customElements.define('cps-loader', customElement);
export const CprLoader = customElementToReact({ name: 'cps-loader' });
