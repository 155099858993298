import React from "react";
import { func, string } from "prop-types";
import { CpIcon } from "@components";
import { useCss, a, k } from "kremling";

CpPill.propTypes = {
  text: string,
  onRemove: func
}

export function CpPill(props) {
  const {
    onRemove,
    text
  } = props;

  const scope = useCss(css)

  return (
    <div {...scope} className={a('pill').m('cp-pr-8', !onRemove)}>
      <span>{text}</span>
      {onRemove &&
        <button
          onClick={(e) => {
            e.stopPropagation()
            onRemove()
          }}
        >
          <CpIcon name="close-small" fill="var(--cps-color-pill-text)" />
        </button>
      }
    </div>
  );
}

const css = k`
.pill {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 .8rem;
  margin-right: .3rem;
  margin-bottom: .3rem;

  border-radius: .3rem;
  border: solid .1rem var(--cp-color-pill-text);
  background-color: var(--cp-color-pill-bg);
  color: var(--cp-color-pill-text);
  font-size: 1.3rem;

  width: fit-content;
  height: 2.4rem;

  user-select: none;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.pill button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .8rem;

  width: 2.2rem;
  height: 2.4rem;
  line-height: 1;

  border: none;
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  border-left: solid .1rem var(--cp-color-pill-text);

  background-color: transparent;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: background-color var(--cp-form-transition-duration) ease;
    background-color: transparent;
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }

  &:hover::after {
    background-color: rgba(0,0,0,.1);
  }

  svg {
    flex-shrink: 0;
    margin-left: -.1rem;
  }
}
`;
