import React, { useEffect, useState } from 'react';
import { CpModal } from '@components';

export function ModalServiceEntry({ id, component, props, modalProps, onSubmit }) {
  const [show, setShow] = useState(true);
  const [data, setData] = useState(false);
  const Component = component;

  useEffect(() => {
    if (!show) setTimeout(() => onSubmit(id, data), 500);
  }, [show]);

  function submit(data) {
    setData(data);
    setShow(false);
  }

  return (
    <CpModal
      {...modalProps}
      show={show}
      onClose={() => submit(false)}
    >
      <Component
        onSubmit={submit}
        {...props}
      />
    </CpModal>
  );
}