// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cps-tooltip-styles__inlineBlock--mLzqB {\n    display: inline-block;\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-tooltip/cps-tooltip.styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB","sourcesContent":[".inlineBlock {\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inlineBlock": "cps-tooltip-styles__inlineBlock--mLzqB"
};
export default ___CSS_LOADER_EXPORT___;
