import React, { useEffect, useState } from 'react';
import { useCss, a } from 'kremling';
import ReactDOM from 'react-dom';

import { durationInMillis, ModalContext } from '../cp-modal/modal.utils';
import { DrawerMount } from './drawer.utils'
import { useClickEventStack, useIsMounted } from '@hooks';

export function DrawerContainerComponent(props) {
  const {
    animScale,
    children,
    className,
    onAfterClose,
    onAfterOpen,
    onClose,
    show,
    styles = {},
  } = props;
  const scope = useCss(css(animScale));
  useClickEventStack(() => {}, !show);
  const [isVisible, setIsVisible] = useState(show);
  const isMounted = useIsMounted().current;

  useEffect(() => {
    if (show && !isVisible) {
      setIsVisible(true);
      setTimeout(() => {
        if (isMounted) onAfterOpen?.();
      }, durationInMillis);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      onAfterClose?.();
      setIsVisible(false);
    }
  }

  if (!isVisible) return null;
  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ onClose }}>
      <div
        {...scope}
        className={a('cp-drawer-container')
          .m('cp-drawer-container--animate-out', !show)
          .m('cp-drawer-container--animate-in', show)
        }
        onAnimationEnd={onAnimationEnd}
      >
        <div
          className={a('cp-drawer cp-card-l3').a(className)}
          style={styles}
        >
          {children}
        </div>
      </div>
      <DrawerMount />
    </ModalContext.Provider>,
    document.body
  );
}

DrawerContainerComponent.propTypes = {};

// language=scss
const css = (scale) => `
  .cp-drawer-container {
    pointer-events: none;
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100001;
    perspective: 20rem;
    transform-style: preserve-3d;
    transform: translate(0);
  }

  .cp-drawer {
    pointer-events: auto;
    position: relative;
    background-color: var(--cp-color-card-bg);
    margin: 3.2rem auto;
    z-index: 1;
    box-shadow: var(--cp-box-shadow-l3);
    transform-origin: center center;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 3.2rem);
  }

  .cp-drawer__body {
    padding: 1.6rem;
    min-height: 6.4rem;
    overflow: auto;
    flex-grow: 1;
  }

  .cp-drawer-container--animate-in {
    animation: drawerFadeIn ${durationInMillis}ms ease;
  }

  .cp-drawer-container--animate-in .cp-drawer {
    animation: drawerTranslateIn ${durationInMillis}ms ease;
  }

  .cp-drawer-container--animate-out {
    animation: drawerFadeOut ${durationInMillis}ms ease;
  }

  .cp-drawer-container--animate-out .cp-drawer {
    animation: drawerTranslateOut ${durationInMillis}ms ease;
  }


  @keyframes drawerFadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes drawerFadeOut {
    0% {
      opacity: 1;
    }

    99% {
      opacity: 0;
    }

    100% {
      display: none;
      opacity: 0;
    }
  }

  @keyframes drawerTranslateIn {
    0% {
      transform: translateX(100%) scale(${scale});
    }

    100% {
      transform: translateX(0%)  scale(1);
    }
  }

  @keyframes drawerTranslateOut {
    0% {
      transform: translateX(0%) scale(1);
    }
    100% {
      transform: translateX(100%) scale(${scale});
    }
  }
`;
