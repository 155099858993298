import React from "react";
import { a } from "kremling";

import { CpIcon, CpBadge } from "@components";
import { useHighlighter } from "@hooks";
import { ItemBase } from "../cp-select/items/item-base";

export function BadgeItemSingle(props) {
  const {
    isSelected,
    value,
    item,
    onChange,
    searchValue,
    Custom = false,
  } = props;
  const isActive = !!(item && value && item.id === value.id);
  const onClick = () => {
    onChange(item);
  };

  const name = useHighlighter(item.name, searchValue);
  const subName = useHighlighter(item.subName, searchValue);

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a("item-single").m("item-single--selected", isSelected)}
      onClick={onClick}
    >
      <CpBadge text={item.name} color={item.color} />
      <div>
        {isActive && (
          <CpIcon
            name="checkmark-circle-filled-small"
            fill="var(--cps-color-success)"
          />
        )}
      </div>
    </ItemBase>
  );
}
