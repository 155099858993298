import React, { forwardRef } from 'react'
import { string, number, object} from 'prop-types';
import { useCss, k, a } from 'kremling';

import { iconMigrateList } from '../../custom-elements/cps-icon/icon-migrate';
import { deprecated } from '@prop-types';

export const CpIcon = forwardRef(function CpIcon({ name, size, className, fill, style, ...rest }, ref) {
  const scope = useCss(css);
  const newName = iconMigrateList[name]
  if (newName && newName !== name) {
    console.warn(`It seems you may be looking for '${newName}' rather than '${name}' for your CpIcon name`)
  }
  return (
    <svg
      ref={ref}
      {...scope}
      className={a(`cp-icon ${name}`).a(className)}
      style={{
        ...(size ? { width: `${size / 10}rem`, height: `${size / 10}rem` } : {}),
        ...(fill ? { fill } : {}),
        ...(style || {}),
      }}
      viewBox="0 0 24 24"
      {...rest}
    >
      <use href={`#${name}`} xlinkHref={`#${name}`} />
    </svg>
  );
});

CpIcon.propTypes = {
  name: string,
  size: deprecated(number, {
    message: "Size is no longer supported on CpIcon. All icons should have a size of 2.4rem (24px)."
  }),
  fill: string,
  style: object,
};

const css = k`
  .cp-icon {
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
  }
`;
