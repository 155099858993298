import React from 'react';
import { CpSelect } from '../cp-select/cp-select.component';
import { array, bool, func, oneOf, oneOfType, string, object } from 'prop-types';
import { defaultSearchFilter, filterAlpha } from '../cp-select/common/utils';
import { CpDropdown } from '@components';
import styles from './../cp-select/cp-select.styles.pcss';
import { Scoped } from 'kremling';

export function CpSelectMultiPills(props) {
  const {
    appendTo,
    className,
    data,
    disabled,
    isGroupData,
    groupType,
    loading,
    onBlur,
    onChange,
    onClose,
    onOpen,
    placeholder,
    preventKeyOpen,
    preventOutsideClickUntilClosed,
    renderFooter,
    renderPill,
    searchFilter,
    searchOnChange,
    searchValue,
    transformData,
    value,
    contentWidth = 'block',
    error,
  } = props;
  return (
    <Scoped css={styles}>
      <CpSelect
        appendTo={appendTo}
        className={className}
        contentWidth={contentWidth}
        data={data}
        disabled={disabled}
        insertDone
        isGroupData={isGroupData}
        groupType={groupType}
        loading={loading}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        preventKeyOpen={preventKeyOpen}
        preventOutsideClickUntilClosed={preventOutsideClickUntilClosed}
        renderFooter={renderFooter || CpSelect.Footer}
        renderGroup={CpSelect.Group}
        renderItem={CpSelect.ItemMulti}
        renderTrigger={triggerProps => (
          <CpSelect.TriggerPills
            onBlur={onBlur}
            renderPill={renderPill || ((val) => val.name)}
            placeholder={placeholder}
            error={error}
            {...triggerProps}
          />
        )}
        searchFilter={defaultSearchFilter(searchFilter, searchValue)}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        transformData={transformData}
        triggerIsBlock
        value={value}
      />
      {typeof error === 'string' &&
        <div className='cp-select--error-text'>{error}</div>
      }
    </Scoped>
  )
}

CpSelectMultiPills.propTypes = {
  appendTo: CpDropdown.propTypes.appendTo,
  preventOutsideClickUntilClosed: CpDropdown.propTypes.preventOutsideClickUntilClosed,

  className: oneOfType([string, object]),
  data: array,
  disabled: bool,
  filter: func,
  isGroupData: bool,
  groupType: oneOf(['header', 'border']),
  loading: bool,
  onBlur: func,
  onChange: func.isRequired,
  onClose: func,
  onOpen: func,
  placeholder: string,
  preventKeyOpen: CpSelect.propTypes.preventKeyOpen,
  renderFooter: CpSelect.propTypes.renderFooter,
  renderPill: func,
  searchFilter: CpSelect.propTypes.searchFilter,
  searchOnChange: func.isRequired,
  searchValue: string.isRequired,
  transformData: func,
  value: array.isRequired,
  error: oneOfType([bool, string]),
};

CpSelectMultiPills.filterAlpha = filterAlpha;
