// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".listbox-styles__hidden--9Cn1U {\n  display: none;\n}\n\n.listbox-styles__listbox--NiLJ\\+ {\n  padding: 0;\n  list-style-type: none;\n  outline: none;\n  overflow-y: auto;\n  min-width: 152px;\n  max-width: 100%;\n  max-height: 280px;\n}\n\n.listbox-styles__open--NCjCv {\n  border-radius: 5px;\n  border: 1px solid var(--cps-color-athens);\n  box-shadow: 0 10px 40px -24px rgba(75,78,83,0.40);\n  display: block;\n  position: absolute;\n  z-index: 10;\n  background-color: white;\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-short-multiselect/listbox/listbox.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,iDAAiD;EACjD,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".hidden {\n  display: none;\n}\n\n.listbox {\n  padding: 0;\n  list-style-type: none;\n  outline: none;\n  overflow-y: auto;\n  min-width: 152px;\n  max-width: 100%;\n  max-height: 280px;\n}\n\n.open {\n  border-radius: 5px;\n  border: 1px solid var(--cps-color-athens);\n  box-shadow: 0 10px 40px -24px rgba(75,78,83,0.40);\n  display: block;\n  position: absolute;\n  z-index: 10;\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "listbox-styles__hidden--9Cn1U",
	"listbox": "listbox-styles__listbox--NiLJ+",
	"open": "listbox-styles__open--NCjCv"
};
export default ___CSS_LOADER_EXPORT___;
