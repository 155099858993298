import React from 'react';
import { CpCheckbox } from '@components';
import { useController } from 'react-hook-form';
import { any, object, string } from 'prop-types';

export function CpCheckboxField(props) {
  const { fieldName, control, required, rules, ...cpToggleProps } = props;
  const {
    field: { value, onBlur, onChange },
  } = useController({ name: fieldName, control, rules });

  return (
    <CpCheckbox
      {...cpToggleProps}
      checked={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

CpCheckboxField.propTypes = {
  control: any.isRequired,
  fieldName: string.isRequired,
  rules: object,
};
