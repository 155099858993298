import React from 'react';
import { bool, object, oneOfType, string } from 'prop-types';

import { CpHeader } from '@components';

export const CardHeader = ({ children, ...rest }) => {
  return (
    <CpHeader {...rest}>
      {children}
    </CpHeader>
  );
}

CardHeader.propTypes = {
  border: bool,
  className: oneOfType([object, string]),
};