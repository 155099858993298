import React, { useMemo } from 'react';
import {
  array,
  bool,
  func,
  object,
  oneOf,
  oneOfType,
  string,
  number,
} from 'prop-types';

import { CpDropdown } from '@components';
import { CpSelectInner } from './cp-select-inner.component';
import { TriggerMultiInput } from './triggers/trigger-multi-input.component';
import { TriggerSingle } from './triggers/trigger-single.component';
import { ItemMulti } from './items/item-multi.component';
import { ItemSingle } from './items/item-single.component';
import { Group } from './common/group.component';
import { buildData, buildGroupData, filterAlpha } from './common/utils';
import { TriggerPills } from './triggers/trigger-pills.component';
import { TriggerMulti } from './triggers/trigger-multi.component';
import { Footer } from './common/footer.component';

export function CpSelect({
  autoSelectOnSearch,
  data: _data,
  isGroupData,
  groupType = 'header',
  preventKeyOpen,
  searchFilter,
  searchValue,
  transformData,
  value: rawValue,
  ...rest
}) {
  const isSearch = !!(searchFilter && searchValue);

  const { isMulti, valueTransformed } = useMemo(() => {
    const isMulti = Array.isArray(rawValue);
    return {
      isMulti,
      valueTransformed: isMulti
        ? transformData
          ? rawValue.map(transformData)
          : rawValue
        : rawValue && (transformData ? transformData(rawValue) : rawValue),
    };
  }, [rawValue]);

  const { data, dataMap } = useMemo(() => {
    return isGroupData
      ? buildGroupData(_data, transformData, searchFilter, searchValue)
      : buildData(_data, transformData, searchFilter, searchValue);
  }, [_data, searchValue, transformData, searchFilter]);

  return (
    <CpSelectInner
      autoSelectOnSearch={autoSelectOnSearch}
      data={data}
      dataMap={dataMap}
      isGroupData={!!isGroupData}
      groupType={groupType}
      isMulti={isMulti}
      isSearch={isSearch}
      preventKeyOpen={preventKeyOpen}
      searchFilter={searchFilter}
      searchValue={searchValue}
      transformData={transformData}
      value={rawValue}
      valueTransformed={valueTransformed}
      {...rest}
    />
  );
}

CpSelect.propTypes = {
  // CpDropdown props
  allowContentClicks: CpDropdown.propTypes.allowContentClicks,
  appendTo: CpDropdown.propTypes.appendTo,
  contentHeight: CpDropdown.propTypes.contentHeight,
  contentWidth: CpDropdown.propTypes.contentWidth,
  cover: CpDropdown.propTypes.cover,
  position: CpDropdown.propTypes.position,
  preventOutsideClickUntilClosed:
    CpDropdown.propTypes.preventOutsideClickUntilClosed,

  // CpSelect props
  autoSelectOnSearch: bool,
  className: oneOfType([object, string]),
  data: array,
  disabled: bool,
  insertSearch: bool,
  isGroupData: bool,
  groupType: oneOf(['header', 'border']),
  loading: bool,
  onChange: func,
  onClose: func,
  onOpen: func,
  placeholder: string,
  preventKeyOpen: bool,
  renderFooter: func,
  renderGroup: func,
  renderItem: func,
  renderTrigger: func,
  searchFilter: oneOfType([func, bool]),
  searchOnChange: func,
  searchValue: string,
  showResultsInContent: bool,
  style: object,
  transformData: func,
  triggerIsBlock: bool,
  value: oneOfType([array, object, string, number]),
};

CpSelect.defaultProps = {
  allowContentClicks: true,
  autoSelectOnSearch: false,
  contentWidth: 'lg',
  position: 'bottom',
  renderGroup: Group,
  preventKeyOpen: false,
};

// triggers
CpSelect.TriggerMulti = TriggerMulti;
CpSelect.TriggerMultiInput = TriggerMultiInput;
CpSelect.TriggerSingle = TriggerSingle;
CpSelect.TriggerPills = TriggerPills;

// items
CpSelect.ItemMulti = ItemMulti;
CpSelect.ItemSingle = ItemSingle;

// group
CpSelect.Group = Group;

// footer
CpSelect.Footer = Footer;

// utils
CpSelect.filterAlpha = filterAlpha;
