import React from "react";
import { arrayOf, func, shape, string } from "prop-types";
import { CpBadge } from "@components";
import {CpSelect} from '../cp-select/cp-select.component';
import {BadgeItemSingle} from './badge-item-single.component';

export function CpSelectBadge({
  appendTo,
  options,
  value,
  onChange,
  emptyCase = "",
  disabled,
  contentWidth,
  position,
  triggerIsBlock,
  renderTrigger=false,
  hideSelected=false, // added with renderTrigger for task dashboard right click change priority (consider removing once a solution better than dropdown in dropdown is enacted)
}) {
  const getCurrentColor = () => {
    const item = options.find(o => o.name === value?.name) || options.find(o => o.name === emptyCase)
    if(disabled) {
      return item.color === 'white' ? 'white' :'var(--cp-color-app-disabled-text)'
    }
    return item.color
  }
  const defaultTrigger = ({ toggle }) => (
    <CpBadge
      onClick={toggle}
      color={getCurrentColor()}
      text={value?.name || emptyCase}
      disabled={disabled}
    />
  );
  return (
    <CpSelect
      appendTo={appendTo}
      contentWidth={contentWidth}
      data={options}
      disabled={disabled}
      position={position}
      onChange={onChange}
      renderItem={BadgeItemSingle}
      renderTrigger={renderTrigger || defaultTrigger}
      triggerIsBlock
      // searchFilter={CpSelect.filterAlpha(search)}
      // searchHideEmptyState
      //  searchOnChange={setSearch}
      //  searchValue={search}
      value={value || (!hideSelected && options.find(o => o.name === emptyCase))}
      transformData={(item) => ({
        id: item.name,
        name: item.name,
        color: item.color,
      })}
    />
  );
}

CpSelectBadge.propTypes = {
  options: arrayOf(shape({name: string.isRequired, color: string.isRequired})),
  emptyCase: (props, propName, componentName) => {
    const emptyCase = props[propName];
    if (emptyCase && !props.options.find(o => o.name === emptyCase)) {
      return new Error(
        `The provided emptyCase '${emptyCase}' does not match any ids in the provided options. Please include an option with id: '${emptyCase}' with a corresponding color for value.`
      );
    }
  },
  onChange: func.isRequired,
  value: (props, propName, componentName) => {
    if (
      props[propName] &&
      !props.options.some(o => o.name === props[propName].name)
    ) {
      return new Error(
        `The value '${props[propName]}' provided to <${componentName} /> is not found in 'options'.`
      );
    }
  },
  renderTrigger: func,
};