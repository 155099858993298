import React from 'react';
import { bool, func, node, object, oneOfType, string } from 'prop-types';

import { ModalContainerComponent } from '../cp-modal/modal-container.component';
import { smallOverlayMax } from '../cp-modal/modal.utils';
import { OverlayHeader } from './components/overlay-header.component';
import { OverlayBody } from './components/overlay-body.component';

export function CpOverlay({ children, styles, small, ...props }) {
  const maxWidth = small ? { maxWidth: `${smallOverlayMax}px`} : {}
  return (
    <ModalContainerComponent
      {...props}
      styles={{
        ...styles,
        width: 'calc(100% - 6.4rem)',
        height: 'calc(100% - 6.4rem)',
        ...maxWidth,
      }}
      animRotateX="-1deg"
      animScale=".92"
    >
      {children}
    </ModalContainerComponent>
  );
}

CpOverlay.propTypes = {
  children: node,
  className: oneOfType([object, string]),
  onClose: func.isRequired,
  show: bool.isRequired,
  styles: object,
  small: bool,
};

// overlay components
CpOverlay.Header = OverlayHeader;
CpOverlay.Body = OverlayBody;
