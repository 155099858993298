import moment from 'moment';

export const dateRangeTypes = {
  today: 'today',
  yesterday: 'yesterday',
  currentWeek: 'currentWeek',
  lastWeek: 'lastWeek',
  currentMonth: 'currentMonth',
  lastMonth: 'lastMonth',
  currentQuarter: 'currentQuarter',
  lastQuarter: 'lastQuarter',
  yearToDate: 'yearToDate',
  lastYear: 'lastYear',
  custom: 'custom',
};

const dateRanges = {
  [dateRangeTypes.today]: {
    label: 'Today',
    filter: {
      before: moment(), // today
      after: moment(),
    },
  },
  [dateRangeTypes.yesterday]: {
    label: 'Yesterday',
    filter: {
      before: moment().subtract(1, 'days'), // yesterday
      after: moment().subtract(1, 'days'),
    },
  },
  [dateRangeTypes.currentWeek]: {
    label: 'Current week',
    filter: {
      after: moment().day(0), // sunday of current week
      before: moment().endOf('week'),
    },
  },
  [dateRangeTypes.lastWeek]: {
    label: 'Last week',
    filter: {
      after: moment().day(-7), // sunday of last week
      before: moment().day(-1), // saturday of last week
    },
  },
  [dateRangeTypes.currentMonth]: {
    label: 'Current month',
    filter: {
      after: moment().date(1), // day 1 of this month
      before: moment().endOf('month'), //last day of the month
    },
  },
  [dateRangeTypes.lastMonth]: {
    label: 'Last month',
    filter: {
      after: moment()
        .subtract(1, 'months')
        .date(1), // day 1 of last month
      before: moment()
        .subtract(1, 'months')
        .endOf('month'),
    },
  },
  [dateRangeTypes.currentQuarter]: {
    label: 'Current quarter',
    filter: {
      after: moment().startOf('quarter'),
      before: moment().endOf('quarter'),
    },
  },
  [dateRangeTypes.lastQuarter]: {
    label: 'Last quarter',
    filter: {
      after: moment()
        .subtract(1, 'quarter')
        .startOf('quarter'),
      before: moment()
        .subtract(1, 'quarter')
        .endOf('quarter'),
    },
  },
  [dateRangeTypes.yearToDate]: {
    label: 'Year to date',
    filter: {
      after: moment().startOf('year'),
      before: moment(),
    },
  },
  [dateRangeTypes.lastYear]: {
    label: 'Last year',
    filter: {
      after: moment()
        .subtract(1, 'year')
        .startOf('year'),
      before: moment()
        .subtract(1, 'year')
        .endOf('year'),
    },
  },
  // custom
  [dateRangeTypes.custom]: {
    label: 'Custom',
    filter: {
      after: moment(), // today
      before: moment(), // today
    },
  },
};

export const defaultDateFilterRanges = {
  [dateRangeTypes.today]: dateRanges[dateRangeTypes.today],
  [dateRangeTypes.yesterday]: dateRanges[dateRangeTypes.yesterday],
  [dateRangeTypes.currentWeek]: dateRanges[dateRangeTypes.currentWeek],
  [dateRangeTypes.lastWeek]: dateRanges[dateRangeTypes.lastWeek],
  [dateRangeTypes.currentMonth]: dateRanges[dateRangeTypes.currentMonth],
  [dateRangeTypes.lastMonth]: dateRanges[dateRangeTypes.lastMonth],
  [dateRangeTypes.custom]: dateRanges[dateRangeTypes.custom],
};
