import React from "react";
import { useCss } from "kremling";
import { string, oneOfType, object } from "prop-types";
import { TextCell } from "./components/cell-renderer.component";

import css from "./cp-table.styles.pcss";

export function CpTable({ data, columns, style }) {
  const scope = useCss(css);

  return (
    <div {...scope} style={style} className="cp-table">
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>
                <div>
                  <span>{column.label}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <td key={column.key}>{column.renderCell(row[column.key])}</td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
}

CpTable.propTypes = {
  className: oneOfType([object, string]),
  style: object,
  data: object,
  columns: object,
};

CpTable.TextCell = TextCell;
