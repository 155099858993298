import React, { useState, useMemo } from "react";
import { string, bool } from "prop-types";
import { CpIcon } from "@components";
import { useCss, k, a } from "kremling";

export const CpNameChip = props => {
  const {
    name,
    className = "",
    active = false,
    size = "medium",
    icon = "person",
    imgSrc
  } = props;

  const scope = useCss(css);
  const [imgError, setImgError] = useState(false)
  const initials = useMemo(() => {
    const sections = name?.split(" ") || [];
    const firstInitial = sections[0]?.charAt(0)?.toUpperCase();
    const lastInitial =
      sections.length > 1 &&
      sections[sections.length - 1]?.charAt(0)?.toUpperCase();

    return firstInitial && lastInitial ? (
      `${firstInitial}${lastInitial}`
    ) : (
      <CpIcon name={icon} />
    );
  }, [name]);

  return (
    <div
      {...scope}
      className={a(`name-chip cps-label-square +${size} ${className}`)
                .m("+active", active)
                .m("transparent", imgSrc && !imgError)}
    >
      {imgSrc && !imgError ? (
        <div className="image-container">
          <img onError={(e) => setImgError(true)} className="image" src={imgSrc} />
        </div>
      ) : (
        <div className="initials">{initials}</div>
      )}
    </div>
  );
};

CpNameChip.propTypes = {
  name: string,
  className: string,
  active: bool,
  size: string,
  icon: string,
  imgSrc: string
};

const css = k`
  .name-chip {
    padding-top: 0;
  }

  .initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .image {
    height: 100%;
  }

  .transparent {
    background: transparent;
  }
`;
