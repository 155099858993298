import React, { forwardRef } from 'react';
import { bool, func, oneOfType, string } from 'prop-types';
import { a } from 'kremling';

import { CpButton } from '@components';

export const TriggerMulti = forwardRef((props, ref) => {
  const {
    // CpSelect Props
    isDisabled,
    toggle,
    triggerIsBlock,
    value,
    // Props
    onBlur,
    placeholder,
    renderValue,
    error,
  } = props;

  let display = placeholder;
  if (value.length) {
    if (renderValue) {
      display = renderValue(value);
    } else {
      if (value.length > 1) {
        display = `${value.length} Selected`;
      } else {
        display = value[0].name;
      }
    }
  }
  return (
    <CpButton
      type="button"
      className={
        a('trigger-single')
          .m('trigger-single--placeholder', !value.length)
          .m('trigger-single--error', error)
      }
      onClick={toggle}
      dropdown
      block={triggerIsBlock}
      ref={ref}
      disabled={isDisabled}
      onBlur={onBlur}
    >
      {display}
    </CpButton>
  );
});

TriggerMulti.propTypes = {
  onBlur: func,
  placeholder: string,
  renderValue: func,
  error: oneOfType([bool, string]),
};
