// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-styles__footer--djkTf {\n  border-top: 1px solid var(--cps-color-silver); \n  padding: 8px 0;\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-short-multiselect/listbox/footer/footer.styles.css"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,cAAc;AAChB","sourcesContent":[".footer {\n  border-top: 1px solid var(--cps-color-silver); \n  padding: 8px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer-styles__footer--djkTf"
};
export default ___CSS_LOADER_EXPORT___;
