// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cps-tab-styles__tabContainer--UmU4q {\n  outline: none;\n  display: inline-flex;\n  align-items: center;\n  font-size: 18px;\n  min-width: 100px;\n  margin-left: 16px;\n  justify-content: center;\n  padding: 8px;\n  border-bottom: 2px solid transparent;\n  transition: border-color 250ms ease-in-out;\n}\n\n.cps-tab-styles__tabContainer--UmU4q:hover {\n  cursor: pointer;\n}\n\n.cps-tab-styles__tabContainer--UmU4q.cps-tab-styles__tabActive--JV1lo {\n  border-bottom: 2px solid var(--cps-color-primary);\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-tab/cps-tab.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,YAAY;EACZ,oCAAoC;EACpC,0CAA0C;AAC5C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iDAAiD;AACnD","sourcesContent":[".tabContainer {\n  outline: none;\n  display: inline-flex;\n  align-items: center;\n  font-size: 18px;\n  min-width: 100px;\n  margin-left: 16px;\n  justify-content: center;\n  padding: 8px;\n  border-bottom: 2px solid transparent;\n  transition: border-color 250ms ease-in-out;\n}\n\n.tabContainer:hover {\n  cursor: pointer;\n}\n\n.tabContainer.tabActive {\n  border-bottom: 2px solid var(--cps-color-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "cps-tab-styles__tabContainer--UmU4q",
	"tabActive": "cps-tab-styles__tabActive--JV1lo"
};
export default ___CSS_LOADER_EXPORT___;
