import React, { useMemo } from 'react';

export const clearSelectionId = '__cp_select_clear_selection__';

export const defaultSearchFilter = (searchFilter, searchValue) => {
  return searchFilter && typeof searchFilter !== 'function'
    ? filterAlpha(searchValue)
    : searchFilter;
};

/**
 * Filter Alpha
 * @param {string} search
 * @param {string|string[]} key
 * @returns {function}
 */
export const filterAlpha = (search, key = ['name', 'subName']) => {
  return (item) => {
    const keys = Array.isArray(key) ? key : [key];
    const regex = new RegExp(
      search.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&'),
      'i'
    );
    if (search) {
      return keys.find((k) => {
        if (!item.hasOwnProperty(k)) return false;
        const value = item[k];
        if (typeof value !== 'string' && typeof value !== 'number') {
          console.warn(
            `CpSelect.filterAlpha: search property "${k}" of type "${typeof value}" is invalid. Search properties require either "string" or "number" types.`
          );
          return false;
        }
        return !!value.toString().match(regex);
      });
    }
    return true;
  };
};

/**
 * Node List To Array
 * @param {NodeList} nodeList
 * @returns {Node[]}
 */
export const nodeListToArray = (nodeList) => {
  let i = nodeList.length;
  let arr = new Array(i);
  while (i--) {
    arr[i] = nodeList[i];
  }
  return arr;
};

function validateData(data, hasDataTransform) {
  let err = [];
  for (let i = 0; i < data.length; i++) {
    if (typeof data[i] !== 'object') {
      err.push(
        `"data" prop must be an array of objects. Current type: ${typeof data[
          i
        ]}`
      );
      break;
    }
    const { id, name, subName } = data[i];
    if (!data[i].hasOwnProperty('id')) {
      err.push(`data property "id" is required`);
    }
    if (typeof id !== 'string' && typeof id !== 'number') {
      err.push(`data property "id" must be of type "string" or "number"`);
    }
    if (!data[i].hasOwnProperty('name')) {
      err.push(`data property "name" is required`);
    }
    if (typeof name !== 'string') {
      err.push(`data property "name" must be of type "string"`);
    }
    if (subName && typeof subName !== 'string') {
      err.push(`data property "subName" must be of type "string"`);
    }
    if (err.length) {
      if (hasDataTransform) {
        err.forEach((e) => console.error(`[CpSelect] ${e}`));
        // eslint-disable-next-line
      } else {
        console.error(
          `[CpSelect] "data" prop is using invalid properties in the collection. Try using the "transformData" to transform it.`
        );
      }
      console.error(
        `[CpSelect] "data" valid prop structure: Array<{ id: string|number, name: string, subName?: string }>`
      );
      break;
    }
  }
  return !err.length;
}

/**
 *  * Build Data
 * Apply filters and transforms to data
 * @param {object[]} data
 * @param {function=} transformData
 * @param {function=} searchFilter
 * @returns {{ data: array, dataMap: object }}
 */
export function buildData(data, transformData, searchFilter, searchValue) {
  const dataMap = {};
  const dataTransformed = transformData
    ? data.map((item) => {
        const newItem = transformData(item);
        dataMap[newItem.id] = item;
        return newItem;
      })
    : data;

  if (
    !validateData(!!transformData ? dataTransformed : data, !!transformData)
  ) {
    return { data: [], dataMap: {} };
  }
  return {
    data: searchFilter
      ? dataTransformed.filter(
          (d) => searchFilter(d) && (searchValue ? !d.subHeader : true)
        )
      : dataTransformed,
    dataMap,
  };
}

/**
 *  * Build Group Data
 * Apply filters and transforms to group data
 * @param {object[]} data
 * @param {function=} transformData
 * @param {function=} searchFilter
 * @returns {{ data: array, dataMap: object }}
 */
export function buildGroupData(data, transformData, searchFilter, searchValue) {
  const dataMap = {};
  // transform data
  const dataTransformed = data.map((group) => {
    return {
      ...group,
      data: transformData
        ? group?.data.map((item) => {
            const newItem = transformData(item);
            dataMap[newItem.id] = item;
            return newItem;
          })
        : group?.data,
    };
  });
  return {
    // apply search filter
    data: searchFilter
      ? dataTransformed
          .map((group) => ({
            ...group,
            data: group.data.filter(
              (d) => searchFilter(d) && (searchValue ? !d.subHeader : true)
            ),
          }))
          .filter((group) => group.data.length)
      : dataTransformed,
    dataMap,
  };
}

export const getItemElements = (ref) => {
  if (!ref.current) return;
  return nodeListToArray(
    ref.current.querySelectorAll(
      `.cp-select-component__content-items > [data-id]`
    )
  );
};

export const scrollToElement = (el) => {
  if (el) el.scrollIntoView({ block: 'nearest' });
};
