// @jsx React.createElement
import React from 'react'
import PropTypes from 'prop-types'
import styles from './cps-time.styles.css'
import {padStart, isNumber, chain, get, noop} from 'lodash'

const regex = /([0-2]?[0-9]):([0-5]?[0-9])(am|pm)?/i
const rowHeight = 36

export class CprTime extends React.Component {
  static propTypes = {
    // Required
    timeUpdated: PropTypes.func.isRequired,

    // Optional
    popupOrientation: PropTypes.oneOf(['below', 'above']),
    handleBlur: PropTypes.func,
    handleFocus: PropTypes.func,
    initialTime: PropTypes.string,
    minTime: PropTypes.string,
    inputClass: PropTypes.string,
    className: PropTypes.string,
  }
  static defaultProps = {
    initialTime: '7:00am',
    minTime: '12:00am',
    maxTime: '11:59pm',
    showErrorBlock: false,
    isValid: true,
    popupOrientation: 'below',
    handleBlur: noop,
    handleFocus: noop,
    className: '',
  }
  constructor(props) {
    super(props)
    const parsedInput = this.getHourAndMinute(props.initialTime)

    this.menuIntervalInMinutes = 30

    const {minIndex, maxIndex} = this.getMinMaxIndex(props)

    this.state = {
      hour: parsedInput.hour,
      minute: parsedInput.minute,
      isValid: parsedInput.isValid,
      inputValue: props.initialTime,
      popupOpen: false,
      activeItemIndex: null,
      minIndex,
      maxIndex,
      menuItemsWithIndex: this.recalculateMenuItems(minIndex, maxIndex),
    }
  }
  componentDidMount() {
    this.inputEl.addEventListener('click', this.stopPropagation, true)
  }
  render() {
    const menuItems = this.state.menuItemsWithIndex
    const visibleMenuItems = Math.min(5, menuItems.length)
    const menuItemHeight = 36
    const dropdownPadding = 18
    const menuHeight = String(visibleMenuItems * menuItemHeight + dropdownPadding) + 'px'
    const dropdownStyles = this.props.popupOrientation === 'above' ? {top: `auto`, bottom: '34px', height: menuHeight} : {height: menuHeight}

    return (
      <span className={`cps-form-group ${!this.state.isValid || !this.props.isValid ? 'cps-has-error' : ''} ${this.props.className}`} style={{marginBottom: 0}}>
        <input
          type="text"
          onFocus={this.handleFocus}
          value={this.state.inputValue}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          className={`cps-form-control`}
          ref={el => this.inputEl = el}
          onKeyDown={this.handleKeyDown}
          disabled={this.props.disabled}
          onClick={this.maybeOpenPopup}
        />
        <span className="cps-form-control-feedback cps-icon-error" />
        {this.props.showErrorBlock &&
          <span className="cps-error-block">
            Invalid time
          </span>
        }
        <div className={`cps-dropdown ${this.state.popupOpen ? 'cps-open' : ''}`}>
          <ul
            className={`cps-dropdown-menu ${styles.dropdown}`}
            style={dropdownStyles}
            ref={el => this.dropdownEl = el}
          >
            {menuItems
              .map(index => {
                const hour = Math.floor(index / 2)
                const minute = index % 2 === 1 ? this.menuIntervalInMinutes : 0

                let readableHour
                if (hour > 12) {
                  readableHour = String(hour - 12)
                } else if (hour === 0) {
                  readableHour = '12'
                } else {
                  readableHour = String(hour)
                }
                const readableMinute = minute === 0 ? '00' : minute
                const readableAmPm = hour >= 12 ? 'pm' : 'am'

                return (
                  <li
                    key={index}
                    className={index === this.state.activeItemIndex ? 'active' : ''}
                    data-item-index={index}
                    data-hour={hour}
                    data-minute={minute}
                    onMouseOver={() => this.setState({activeItemIndex: index})}
                  >
                    <a onClick={() => this.optionSelected(hour, minute)}>
                      {`${readableHour}:${readableMinute}${readableAmPm}`}
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </span>
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.popupOpen && this.state.popupOpen) {
      const roundedMinutes = Math.ceil(this.state.minute / this.menuIntervalInMinutes) * this.menuIntervalInMinutes
      const itemIndex = this.state.hour * 2 + (roundedMinutes === 0 ? 0 : 1)
      this.setState({activeItemIndex: itemIndex}, () => {
        this.scrollDropdownToTime(this.state.hour, roundedMinutes)
      })
    }

    if (this.state.hour !== prevState.hour || this.state.minute !== prevState.minute || this.state.isValid !== prevState.isValid) {
      this.props.timeUpdated({
        hour: this.state.hour,
        minute: this.state.minute,
        isValid: this.state.isValid,
        formattedValue: this.state.inputValue,
      })
    }

    if (prevProps.minTime !== this.props.minTime || prevProps.maxTime !== this.props.maxTime) {
      const {minIndex, maxIndex} = this.getMinMaxIndex()
      const menuItemsWithIndex = this.recalculateMenuItems(minIndex, maxIndex)

      this.setState({minIndex, maxIndex, menuItemsWithIndex})
    }
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.closePopup, true)
    this.inputEl.removeEventListener('click', this.stopPropagation, true)
  }
  recalculateMenuItems = (minIndex, maxIndex) => {
    return chain([...Array(48)])
      .map((_, index) => index)
      .filter(index => index >= minIndex && index <= maxIndex)
      .value()
  }
  updateTime = newTime => {
    const hourAndMinute = this.getHourAndMinute(newTime)
    const inputValue = this.formatHourAndMinute(hourAndMinute.hour, hourAndMinute.minute)
    this.setState({
      ...hourAndMinute,
      inputValue,
    })
  }
  scrollDropdownToItemIndex = index => {
    // Auto scroll so the selected option is in the middle of the 5 visible options
    index = Math.min(48, index)
    index = Math.max(0, index)
    const paddingAbove = 8
    const pixelsAboveToCenter = rowHeight * 2 + paddingAbove
    const liEl = this.dropdownEl.querySelector(`li[data-item-index="${index}"]`)
    this.dropdownEl.scrollTop = Math.max(0, liEl.offsetTop - pixelsAboveToCenter)
  }
  scrollDropdownToTime = (hour, minute) => {
    const {minIndex, maxIndex} = this.getMinMaxIndex()

    let itemIndex = hour * 2
    itemIndex += (minute >= this.menuIntervalInMinutes ? 1 : 0)
    itemIndex = Math.min(maxIndex, itemIndex)
    itemIndex = Math.max(minIndex, itemIndex)

    this.scrollDropdownToItemIndex(itemIndex)
  }
  stopPropagation = evt => evt.cpsTimeComponent = this
  handleBlur = evt => {
    this.props.handleBlur(evt.nativeEvent)

    const {hour, minute, isValid} = this.getHourAndMinute(evt.target.value)
    this.setState({isValid, hour, minute, inputValue: this.formatHourAndMinute(hour, minute)})
  }
  chooseAmPm = (hour, minute, minTime, maxTime) => {
    if (this.withinMinMaxTime(hour, minute, minTime, maxTime)) {
      // If the hour they typed fits within minTime and maxTime
      return hour < 12 ? 'am' : 'pm'
    }

    if (hour < 12 && this.withinMinMaxTime(hour + 12, minute, minTime, maxTime)) {
      // If we force pm, the time they typed in fits the minTime and maxTime
      return 'pm'
    }

    // Default to within the business day
    return hour > 7 && hour < 12 ? 'am' : 'pm'
  }
  withinMinMaxTime = (hour, minute, minTime, maxTime) => {
    const greaterThanMinTime = hour > minTime.hour || (hour === minTime.hour && minute >= minTime.minute)
    const lessThanMaxTime = hour < maxTime.hour || (hour === maxTime.hour && minute <= maxTime.minute)
    return greaterThanMinTime && lessThanMaxTime
  }
  getHourAndMinute = (inputValue, isMinMaxTime = false) => {
    const match = regex.exec(inputValue)
    if (!match) {
      return {hour: this.state.hour, minute: this.state.minute, isValid: false, inputValue}
    }

    let hour = parseInt(match[1]) // safe because the regex verified it's an integer
    let minute = parseInt(match[2]) // safe because the regex verified this

    let isValid = true

    let minTime, maxTime
    // This if statement prevents inf recursion
    if (!isMinMaxTime) {
      minTime = this.getHourAndMinute(this.props.minTime, true)
      maxTime = this.getHourAndMinute(this.props.maxTime, true)
    }

    let amPm
    if (match[3]) {
      amPm = match[3].toLowerCase()
    } else if (hour > 12) {
      amPm = 'pm'
    } else if (isMinMaxTime) {
      throw new Error('The minTime and maxTime props for cps-time should specify am/pm.')
    } else {
      amPm = this.chooseAmPm(hour, minute, minTime, maxTime)
    }

    if (hour > 23) {
      return {hour: this.state.hour, minute: this.state.minute, isValid: false, inputValue}
    }

    if (amPm === 'pm' && hour < 12) {
      hour += 12
    }

    if (amPm === 'am' && hour === 12) {
      hour = 0
    }

    if (!isMinMaxTime) {
      isValid = isValid && this.withinMinMaxTime(hour, minute, minTime, maxTime)
    }

    return {hour, minute, isValid}
  }
  formatHourAndMinute = (hour, minute) => {
    const hourLessThanTwelve = hour % 12
    const formattedHour = hourLessThanTwelve === 0 ? '12' : String(hourLessThanTwelve)
    const formattedMinute = padStart(String(minute), 2, '0')
    const formattedAmPm = hour >= 12 ? 'pm' : 'am'

    return formattedHour + ":" + formattedMinute + formattedAmPm
  }
  optionSelected = (hour, minute) => {
    const inputValue = this.formatHourAndMinute(hour, minute)
    const hourAndMinute = this.getHourAndMinute(inputValue)
    this.setState({
      inputValue,
      ...hourAndMinute
    }, () => {
      this.inputEl.select()
      this.closePopup()
    })
  }
  handleFocus = evt => {
    this.props.handleFocus(evt.nativeEvent)
    this.maybeOpenPopup(evt)
  }
  maybeOpenPopup = evt => {
    const target = evt.target

    if (this.state.menuItemsWithIndex.length === 0) {
      // Select the text in the input, but don't open an empty popup
      target.select()
    } else {
      this.setState({popupOpen: true}, () => {
        document.addEventListener('click', this.closePopup, true)
        target.select()
      })
    }
  }
  handleInputClick = evt => {
    if (!this.state.popupOpen) {
      this.handleFocus(evt)
    }
  }
  closePopup = evt => {
    if (!evt || evt.cpsTimeComponent !== this) {
      this.setState({popupOpen: false})
      document.removeEventListener('click', this.closePopup, true)
    }
  }
  handleKeyDown = evt => {
    if (evt.keyCode === 13 || evt.keyCode === 9) {
      // enter or tab
      const {hour, minute, isValid} = this.getHourAndMinute(evt.target.value)
      if (hour !== this.state.hour || minute !== this.state.minute || isValid !== this.state.isValid) {
        this.setState({hour, minute, isValid, popupOpen: false})
      } else if (this.state.popupOpen && evt.keyCode !== 9) { // Tab keypress should not set time to highlighted popup menu item
        const activeItem = this.dropdownEl.querySelector(`li[data-item-index="${this.state.activeItemIndex}"]`)
        this.optionSelected(Number(activeItem.dataset.hour), Number(activeItem.dataset.minute))
      } else { // don't update the value, but close the interval select popup
        this.setState({popupOpen: false})
      }
    }

    if (this.state.popupOpen) {
      if (evt.keyCode === 38) {
        // arrow up
        this.setState(prevState => ({
          activeItemIndex: Math.max(this.getMinMaxIndex().minIndex, prevState.activeItemIndex - 1),
        }), () => {
          this.scrollDropdownToItemIndex(this.state.activeItemIndex)
        })
      }

      if (evt.keyCode === 40) {
        // arrow down
        this.setState(prevState => ({
          activeItemIndex: Math.min(this.getMinMaxIndex().maxIndex, prevState.activeItemIndex + 1),
        }), () => {
          this.scrollDropdownToItemIndex(this.state.activeItemIndex)
        })
      }
    }

    if (evt.keyCode === 27) {
      // Escape key should undo what they have typed and close popup
      this.setState(prevState => ({inputValue: this.formatHourAndMinute(prevState.hour, prevState.minute)}), () => {
        this.closePopup()
      })
    }
  }
  handleChange = evt => {
    this.setState({inputValue: evt.target.value})
  }
  getMinMaxIndex = () => {
    const minTime = this.getHourAndMinute(this.props.minTime)
    const maxTime = this.getHourAndMinute(this.props.maxTime)

    const minIndex = Math.max(0, this.getMenuItemIndex(minTime, 0))
    const maxIndex = Math.min(47, this.getMenuItemIndex(maxTime, 47))

    return {minIndex, maxIndex}
  }
  getMenuItemIndex = (time, defaultIndex) => {
    if (time.isValid) {
      return time.hour * 2 + (Math.ceil(time.minute / this.menuIntervalInMinutes))
    } else {
      return defaultIndex
    }
  }
}


window.customElements.define('cps-time', CprTime)
