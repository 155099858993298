import { useEffect } from "react";
import { keydownEventStack } from "@helpers";

export function useKeydownEventStack({ keys, callback, disabled = false }) {
  useEffect(() => {
    if (!disabled) {
      const listener = keydownEventStack.add({ keys, callback });
      return () => listener.remove();
    }
  }, [disabled]);
}
