import React from 'react';
import PropTypes from 'prop-types';
import { CprCalendar } from '../../../custom-elements/cps-datepicker/cps-calendar.component';

const DateFilter = ({ applyFilter, selectedDate }) => {
  return (
    <div>
      <li className="cps-divider cps-margin-left-16 cps-margin-right-16 cps-margin-top-4 cps-margin-bottom-4" />
      <CprCalendar date={selectedDate} events={{ datechange: applyFilter }} />
    </div>
  );
};

DateFilter.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
};

export default DateFilter;
