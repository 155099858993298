import React from 'react'
import PropTypes from 'prop-types'
import { customElementToReact } from '../custom-element-to-react.js';
import { reactToCustomElement } from '../react-to-custom-element.js';

import { get, noop } from 'lodash';
import { CprButton } from '../cps-button/cps-button.component.js'

const iconSizeOptions = {
  tiny: 56,
  small: 80,
  large: 112,
};

// exported only for tests, not for use outside of canopy-styleguide
export default class CpsEmptyState extends React.Component {
  render() {
    const {
      iconSrc,
      iconSize = 'small',
      noPadding = false,
      headText = "You haven't created one of these",
      headSize = 'cps-title',
      subheadText = '',
      subheadSize = 'cps-body',
      showCta = false,
      ctaClickHandler = noop,
      ctaButtonText = 'Add one now',
      ctaButtonType = 'primary',
      disableCtaOnClick = false,
    } = this.props;

    let showCallToAction =
      showCta
        .toString()
        .trim()
        .toLowerCase() === 'false'
        ? false
        : true;

    let disableCtaButtonOnClick =
      disableCtaOnClick
        .toString()
        .trim()
        .toLowerCase() === 'false'
        ? false
        : true;

    const iconWidth = get(
      iconSizeOptions,
      iconSize
        .toString()
        .trim()
        .toLowerCase(),
      iconSizeOptions.small
    );

    const iconSource = getIconSource(iconSrc);

    return (
      <div className={`${noPadding ? 'cps-padding-0' : 'cps-padding-32'} cps-text-center`}>
        <img id="es-icon" src={iconSource} width={iconWidth} style={{ maxWidth: '100%' }} />
        <div className={`${headSize} cps-wt-bold cps-color-sleeping-panther`}>
          {headText}
        </div>
        {subheadText && (
          <div className={`${subheadSize} cps-margin-top-8`}>
            {subheadText}
          </div>
        )}

        {showCallToAction ? (
          <CprButton
            is="cps-button"
            className="cps-margin-top-16"
            actionType={ctaButtonType}
            disableOnClick={disableCtaButtonOnClick}
            onClick={ctaClickHandler}>
            {ctaButtonText}
          </CprButton>
        ) : null}
      </div>
    );
  }
}

function getIconSource(iconSrc) {
  return hasImageExtension(iconSrc)
    ? iconSrc
    : `https://cdn.canopytax.com/static/empty-states/${iconSrc}.svg`;
}

function hasImageExtension(iconSrc) {
  return Boolean(/\.(png|jpe?g|gif|webp|svg)(\?.*)?$/.test(iconSrc.toString()));
}

const elementName = 'cps-empty-state';
const customElement = reactToCustomElement(CpsEmptyState, {
  parentClass: HTMLElement,
  properties: [
    'iconSrc',
    'iconSize',
    'noPadding',
    'showCta',
    'ctaClickHandler',
    'ctaButtonText',
    'ctaButtonType',
    'disableCtaOnClick',
    'headText',
    'headSize',
    'subheadText',
    'subheadSize',
  ],
});
customElements.define(elementName, customElement);
export const CprEmptyState = customElementToReact({ name: elementName });

CpsEmptyState.propTypes = {
  iconSrc: PropTypes.string,
  iconSize: PropTypes.oneOf(['small', 'large']),
  noPadding: PropTypes.bool,
  headText: PropTypes.string,
  headSize: PropTypes.string,
  subheadText: PropTypes.string,
  subheadSize: PropTypes.string,
  showCta: PropTypes.bool,
  ctaClickHandler: PropTypes.func,
  ctaButtonText: PropTypes.string,
  ctaButtonType: PropTypes.string,
  disableCtaOnClick: PropTypes.bool,
}

CpsEmptyState.defaultProps = {
  iconSrc: '',
  iconSize: 'small',
  noPadding: false,
  headText: "You haven't created one of these",
  headSize: 'cps-title',
  subheadText: '',
  subheadSize: 'cps-body-sm',
  showCta: false,
  ctaClickHandler: noop,
  ctaButtonText: 'Add one now',
  ctaButtonType: 'primary',
  disableCtaOnClick: false,
}
