import React from 'react';
import { bool, func, node, object, oneOfType, string, number } from 'prop-types';
import { range } from '@prop-types';

import { DrawerContainerComponent } from './drawer-container.component';
import { DrawerBody } from './components/drawer-body.component'
import { forceRange, ModalContext as _ModalContext } from '../cp-modal/modal.utils';
import { drawerWidthMax, drawerWidthMin } from './drawer.utils';
import { CpModal } from '@components';

export function CpDrawer({ children, styles, top = 0, width = 320, ...props }) {
  return (
    <DrawerContainerComponent
      {...props}
      styles={{
        width: `${forceRange(width, drawerWidthMin, drawerWidthMax) / 10}rem`,
        maxHeight: `calc(100vh - ${top + 48}px)`,
        position: 'absolute',
        top: `${top}px`,
        right: '0',
        margin: '2.4rem',
        ...styles,
      }}
      animScale=".92"
      visibleBackdrop={false}
    >
      {children}
    </DrawerContainerComponent>
  );
}


CpDrawer.Header = CpModal.Header;
CpDrawer.Body = DrawerBody;
CpDrawer.Footer = CpModal.Footer;

CpDrawer.propTypes = {
  children: node,
  className: oneOfType([object, string]),
  onClose: func.isRequired,
  show: bool.isRequired,
  styles: object,
  top: number,
  width: range(drawerWidthMin, drawerWidthMax),
};
