import React, { useEffect, useRef } from 'react'
import { useCss, a } from 'kremling';
import { func, string } from 'prop-types'

export function CpTextarea (props) {

  const {onChange, className, style, ...rest} = props
  const messageRef = useRef(null)

  useEffect(() => adjustHeight(), [])

  useEffect(() => {
    adjustHeight()
  }, [props.value])

  const adjustHeight = () => {
    messageRef.current.style.height = "auto";
    messageRef.current.style.height = messageRef.current.scrollHeight + "px";
  };

  return (
    <div className={a('cp-textarea').a(className)} {...useCss(css)} style={style}>
      <textarea
        rows={1}
        {...rest}
        ref={messageRef}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
}

CpTextarea.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

// language=css
const css = `
  .cp-textarea {
    display: flex;
    align-items: stretch;
    border: 1px solid var(--cps-color-border);
    border-radius: 4px;
    padding: 0 12px;
    transition: box-shadow var(--cp-form-transition-duration) ease;
    background-color: var(--cps-color-light-well);
  }
  .cp-textarea textarea {
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    overflow: auto;
    padding: 4px 0;
    font-size: 1.4rem;
  }
  .cp-textarea:focus-within {
    border-color: var(--cps-color-primary);
    box-shadow: var(--cp-form-focus-state);
  }
  .cp-textarea:focus-within .cp-icon {
    color: var(--cps-color-primary);
  }
`;
