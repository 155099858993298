import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { array, bool, func, oneOfType, string } from "prop-types";
import { a } from 'kremling';

import { CpIcon } from '@components';

export const TriggerPills = forwardRef((props, ref) => {
  const {
    isDisabled,
    isOpen,
    onBlur,
    onChange,
    open,
    placeholder,
    renderPill,
    searchOnChange,
    searchValue,
    value,
    error,
  } = props;
  const inputEl = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [prevSearchValue, setPrevSearchValue] = useState(null)

  const handleFocus = () => {
    setIsFocused(true);
    inputEl.current.setSelectionRange(10000, 10000);
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  const removeItem = useCallback((item) => {
    onChange(item || null);
  }, []);

  const onKeyUp = useCallback((e) => {
    const val = e.target.value;
    if (e.key === 'Backspace' && val === prevSearchValue && !val && value.length) {
      const item = value[value.length - 1];
      searchOnChange(renderPill(item));
      removeItem(item);
    }
  }, [prevSearchValue, value]);

  return (
    <div
      ref={ref}
      className={
        a('trigger-pills')
          .m('trigger-pills--focused', isFocused)
          .m('trigger-pills--disabled', isDisabled)
          .m('trigger-pills--error', error)
      }
      onClick={() => {
        if (!isFocused) inputEl.current.focus();
        if (!isOpen) {
          open();
        }
      }}
    >
      <div className="trigger-pills__icon">
        <CpIcon name="misc-magnifying-glass-small" />
      </div>
      {value.map(item => (
        <div key={item.id} className="trigger-pills__pill">
          <span>{renderPill(item)}</span>
          <button
            tabIndex={-1}
            onClick={(e) => {
              e.stopPropagation()
              removeItem(item)}
            }
            disabled={isDisabled}
          >
            <CpIcon name="close-small" fill="var(--cps-color-pill-text)" />
          </button>
        </div>
      ))}
      <div className="trigger-pills__input">
        <input
          ref={inputEl}
          disabled={isDisabled}
          type="text"
          autoComplete="off"
          placeholder={!value.length ? placeholder : ''}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={e => searchOnChange(e.target.value)}
          value={searchValue}
          onKeyDown={e => setPrevSearchValue(e.target.value)}
          onKeyUp={onKeyUp}
        />
      </div>
    </div>
  );
});

TriggerPills.propTypes = {
  onBlur: func,
  value: array,
  error: oneOfType([bool, string]),
};

TriggerPills.defaultProps = {
  value: [],
};
