import React, { forwardRef } from 'react';
import { a } from 'kremling';

import { CpButton, CpIcon } from '@components';

export const TriggerSingle = forwardRef((
  {
    isDisabled,
    isOpen,
    onBlur,
    placeholder,
    title,
    toggle,
    triggerIsBlock,
    value,
    error,
  }, ref) => {
  return (
    <CpButton
      type="button"
      className={a("trigger-single").m("trigger-single--placeholder", !value).m('cp-pl-12', value?.icon).m('trigger-single--error', error)}
      onClick={toggle}
      dropdown
      block={triggerIsBlock}
      ref={ref}
      disabled={isDisabled}
      onBlur={(e) => !isOpen && onBlur(e)}
      title={title}
    >
      {value?.icon && (
        <CpIcon
          className="cp-mr-8"
          name={value.icon}
          fill="var(--cp-color-input-disabled-search-icon)"
        />
      )}
      {value ? value.name : placeholder}
    </CpButton>
  );
});
