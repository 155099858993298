import React, {useRef, useState, useEffect} from 'react'
import {reactToCustomElement} from '../react-to-custom-element.js'
import styles from './cps-button.styles.css'
import _CprButton from './cpr-button.component.js'
import {kebabCase} from 'lodash'

/* The cps-button custom element is not responsible for creating a button. Instead, it modifies a button created by
 * the user of the custom element. For example, the user of the custom element should create all of the following dom elements:
 * <cps-button>
 *   <button>
 *     Hi
 *   </button>
 * </cps-button>
 *
 * And cps-button will just add css and a bit of js functionality. Frameworks like React should be tolerant of cps-button
 * controlling some of the css and the disabled prop for the <button />.
 */

// dummyButton is just a way to not have to have defensive code everywhere to check if the user of the
// custom element has provided a <button> inside of <cps-button>
const dummyButton = document.createElement('button')

function CpsButton(props) {
  const disableOnClick = forceBoolean(props.disableOnClick)
  const phat = forceBoolean(props.phat)
  const showLoader = forceBoolean(props.showLoader)
  const showLoaderOnClick = forceBoolean(props.showLoaderOnClick)

  const [buttonEl, setButtonEl] = useState(dummyButton)
  const [forceShowLoader, setForceShowLoader] = useState(false)

  const shouldShowLoader = showLoader || forceShowLoader

  useInnerHTMLButton(props.customElement, buttonEl, setButtonEl)
  useCssStyles(buttonEl, props.actionType, phat, shouldShowLoader)
  useLoaderDots(buttonEl, shouldShowLoader)
  useShowLoaderOnClick(buttonEl, showLoaderOnClick, setForceShowLoader)
  useDisableOnClick(buttonEl, disableOnClick)

  // cps-button isn't responsible for rendering the <button /> or anything else besides the loader dots (which are handled in a different way)
  return null
}

CpsButton.defaultProps = {
  actionType: 'unstyled',
}

function useInnerHTMLButton(customElement, buttonEl, setButtonEl) {
  const actualInnerHtmlButton = customElement.querySelector('button')

  useEffect(() => {
    setButtonEl(actualInnerHtmlButton || dummyButton)
  }, [actualInnerHtmlButton === buttonEl])
}

function useCssStyles(buttonEl, actionType, phat, showLoader) {
  useEffect(() => {
    buttonEl.classList.toggle(styles.raisedButton, actionType === 'primary' || actionType === 'secondary')
    buttonEl.classList.toggle(styles.primary, actionType === 'primary')
    buttonEl.classList.toggle(styles.secondary, actionType === 'secondary')
    buttonEl.classList.toggle(styles.unstyled, actionType === 'unstyled')
    buttonEl.classList.toggle(styles.flat, actionType === 'flat')
    buttonEl.classList.toggle(styles.tertiary, actionType === 'tertiary')
    buttonEl.classList.toggle(styles.phat, phat)
    buttonEl.classList.toggle(styles.loading, showLoader)
    buttonEl.classList.add(styles.button)
  }, [buttonEl, actionType, phat, showLoader])
}

function useLoaderDots(buttonEl, shouldShowLoader) {
  useEffect(() => {
    if (shouldShowLoader) {
      const cpsLoaderEl = document.createElement('cps-loader')
      cpsLoaderEl.customClass = styles.loaderElement
      buttonEl.appendChild(cpsLoaderEl)

      return () => {
        buttonEl.removeChild(cpsLoaderEl)
      }
    }
  }, [buttonEl, shouldShowLoader])
}

function useShowLoaderOnClick(buttonEl, showLoaderOnClick, setForceShowLoader) {
  useEffect(() => {
    if (showLoaderOnClick) {
      buttonEl.addEventListener('click', showLoaderNow)

      return () => {
        buttonEl.removeEventListener('click', showLoaderNow)
      }
    }
  }, [buttonEl, showLoaderOnClick])

  function showLoaderNow() {
    setForceShowLoader(true)
  }
}

function useDisableOnClick(buttonEl, disableOnClick) {
  useEffect(() => {
    if (disableOnClick) {
      buttonEl.addEventListener('click', disableButton)

      return () => {
        buttonEl.removeEventListener('click', disableButton)
      }
    }
  }, [buttonEl, disableOnClick])

  function disableButton(evt) {
    buttonEl.disabled = true
  }
}

function forceBoolean(value) {
  if (typeof value === 'string') {
    return value === 'true' ? true : false
  } else {
    return Boolean(value)
  }
}

export const properties = ['actionType', 'disableOnClick', 'showLoader', 'showLoaderOnClick', 'phat']
export const propertyNameToAttributeName = properties.reduce((result, propName) => {
  result[propName] = kebabCase(propName)
  return result
}, {})
const customElement = reactToCustomElement(CpsButton, {properties});
customElements.define('cps-button', customElement);
export const CprButton = _CprButton
