import React from 'react'
import ReactDOM from 'react-dom'
import {reactToCustomElement} from '../react-to-custom-element.js';
import {customElementToReact} from '../custom-element-to-react.js';
import styles from './cps-tooltip.styles.css';
import {throttle, isEqual, get} from 'lodash';
import TooltipPopup from './tooltip-popup.component.js';

// Exported only for testing purposes. Shouldn't be used outside of canopy-styleguide project
export default class CpsTooltip extends React.Component {
  state = {
    renderTooltip: false,
  }
  static defaultProps = {
    customClassName: '',
    htmlIsTrusted: false,
  }

  constructor(props) {
    super(props)
    this.reactContainer = document.createElement('div');
  }

  componentDidMount() {
    this.mouseOverListener = throttle(this.mousedOver, 10);
    this.mouseLeaveListener = throttle(this.mouseLeave, 10);
    this.mouseOutListener = throttle(this.mouseLeave, 10);

    this.props.customElement.addEventListener('mouseover', this.mouseOverListener);
    // I think IE11 has a bug where mouseleave doesn't always get fired for inline styled elements. So we do both mouseout and mouseleave
    this.props.customElement.addEventListener('mouseleave', this.mouseLeaveListener);
    this.props.customElement.addEventListener('mouseout', this.mouseOutListener);

    this.setCustomElementClassName()
  }

  componentDidUpdate(prevProps) {
    this.setCustomElementClassName()
    if(this.props.disabled && prevProps.disabled !== this.props.disabled) {
      this.hideTooltip()
    }
  }
  render() {

    /* Sometimes render is invoked before tooltip element is connected to the DOM, In these cases offsetParent is null.
    Render will always be called when tooltip is actually connected to the DOM. */
    if(!this.positionedAncestor) {
      this.positionedAncestor = this.props.tooltipContainer || this.props.customElement.offsetParent;
      if(this.positionedAncestor) this.positionedAncestor.appendChild(this.reactContainer);
    }
    const offsetParent = this.props.customElement.offsetParent

    if (this.props.disabled || !this.state.renderTooltip || !this.positionedAncestor || !offsetParent) {
      return null;
    }

    //getBoundingClientRect is based on the viewport x/y, and NOT the document's x/y. So to keep the tooltip in the same location in the document, we need to also account for any x/y scrolling that has happened
    const startingLeft = this.props.tooltipContainer ? (offsetParent.getBoundingClientRect().left + window.scrollX - get(offsetParent, 'scrollLeft', 0)) : 0;
    const startingTop = this.props.tooltipContainer ? (offsetParent.getBoundingClientRect().top + window.scrollY - get(offsetParent, 'scrollTop', 0)) : 0;

    const props = {...this.props, tooltipShown: this.tooltipShown, startingLeft, startingTop, keepTooltipOpen: this.keepTooltipOpen, closeTooltipNow: this.hideTooltip};
    const thingToRender = this.state.renderTooltip ? React.createElement(TooltipPopup, props) : '';
    return ReactDOM.createPortal(thingToRender, this.reactContainer);
  }

  componentWillUnmount() {
    clearTimeout(this.hideTooltipTimeout);

    this.props.customElement.removeEventListener('mouseover', this.mouseOverListener);
    this.props.customElement.removeEventListener('mouseleave', this.mouseLeaveListener);
    this.props.customElement.removeEventListener('mouseout', this.mouseOutListener);

    if (this.positionedAncestor && this.positionedAncestor.children && Array.from(this.positionedAncestor.children).indexOf(this.reactContainer) !== -1) {
      this.positionedAncestor.removeChild(this.reactContainer)
    }
  }
  setCustomElementClassName = () => {
    // Custom elements default to inline, but inline-block is necessary to calculate height/width correctly
    this.props.customElement.className = `${styles.inlineBlock} ${this.props.customClassName}`
  }
  mousedOver = evt => {
    clearTimeout(this.hideTooltipTimeout);
    delete this.hideTooltipTimeout;

    //don't force a rerender, which creates duplicate tooltips, if we already have one
    if(!this.state.renderTooltip) this.setState({renderTooltip: true});
  }
  mouseLeave = evt => {
    const timeToWait = this.props.allowInteraction ? 500 : 0;
    if (this.hideTooltipTimeout) {
      clearTimeout(this.hideTooltipTimeout);
    }
    this.hideTooltipTimeout = setTimeout(this.hideTooltip, timeToWait);
  }
  tooltipShown = el => {
    this.props.customElement.dispatchEvent(new CustomEvent('cps-tooltip:shown', {detail: {tooltipEl: el}}));
  }
  keepTooltipOpen = () => {
    clearTimeout(this.hideTooltipTimeout);
    delete this.hideTooltipTimeout;
  }
  hideTooltip = () => {
    if (this.state.renderTooltip === false) return;

    this.setState({renderTooltip: false}, () => {
      this.props.customElement.dispatchEvent(new CustomEvent('cps-tooltip:hidden'));
    });
  }
}

const customElement = reactToCustomElement(
  CpsTooltip,
  {
    parentClass: HTMLElement,
    properties: [
      'html',
      'htmlIsTrusted',
      'disabled',
      'delayTime',
      'tooltipContainer',
      'useFixedPosition',
      'left',
      'top',
      'allowInteraction',
      'caretOnBottom',
      'caretLeft',
      'caretMiddle',
      'caretRight',
      'theme',
      'showAbove',
      'customClassName',
      'scrollContainerRef',
    ]
  }
);
customElements.define('cps-tooltip', customElement);
export const CprTooltip = customElementToReact({name: 'cps-tooltip'});
