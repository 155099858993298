import React, {useEffect, useRef, useState} from 'react'
import {properties as customElementPropNames, propertyNameToAttributeName} from './cps-button.component.js'
import {forEach, without} from 'lodash'
import PropTypes from 'prop-types'

export default function CprButton(props) {
  const buttonProps = {...props}
  const customElementProps = {}

  customElementPropNames.forEach(customElementPropName => {
    const value = props[customElementPropName]

    if (value !== undefined) {
      delete buttonProps[customElementPropName]
      customElementProps[customElementPropName] = value
    }
  })

  const cpsButtonRef = useRef(null)
  const buttonRef = useRef(null)
  const [prevClassName, setPrevClassName] = useState("")

  // isMounted is never used, but we need to cause a rerender after it is mounted, so this is a way to do it with useEffect
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])

  useCustomElementProperties(cpsButtonRef.current, customElementProps)

  // the className prop needs to be shared between CprButton and CpsButton, so we handle it specially
  delete buttonProps.className
  useClassName(buttonRef.current, props.className, prevClassName, setPrevClassName)

  return (
    <cps-button ref={cpsButtonRef}>
      <button {...buttonProps} ref={buttonRef} />
    </cps-button>
  )
}

CprButton.propTypes = {
  actionType: PropTypes.oneOf(['unstyled', 'primary', 'secondary', 'tertiary', 'flat']),
  className: PropTypes.string,
  disableOnClick: PropTypes.bool,
  phat: PropTypes.bool,
  showLoader: PropTypes.bool,
  showLoaderOnClick: PropTypes.bool,
}
CprButton.defaultProps = {
  actionType: 'unstyled',
  className: '',
  disableOnClick: false,
  phat: false,
  showLoader: false,
  showLoaderOnClick: false,
}

function useCustomElementProperties(cpsButtonEl, customElementProps) {
  useEffect(() => {
    if (cpsButtonEl) {
      forEach(customElementProps, (value, key) => {
        if (typeof value === 'string') {
          cpsButtonEl.setAttribute(propertyNameToAttributeName[key], value)
        } else {
          cpsButtonEl[key] = value
        }
      })
    }
  }, [cpsButtonEl, customElementProps])
}

function useClassName(buttonEl, className, prevClassName, setPrevClassName) {
  useEffect(() => {
    if (buttonEl && prevClassName !== className) {
      // Setting className would overwrite the classes that cps-button puts on the button
      const currentClasses = className.trim().split(/\s/)
      const prevClasses = prevClassName.trim().split(/\s/)

      const classesToAdd = without(currentClasses, ...prevClasses)
      const classesToRemove = without(prevClasses, ...currentClasses)

      classesToAdd.forEach(klass => {
        if (klass) {
          buttonEl.classList.add(klass)
        }
      })
      classesToRemove.forEach(klass => {
        if (klass) {
          buttonEl.classList.remove(klass)
        }
      })

      setPrevClassName(className)
    }
  }, [buttonEl, className, prevClassName])
}
