import React, { useMemo } from 'react';
import { CpSelectMultiPills } from '@components';
import { useController } from 'react-hook-form';
import { any, bool, object, string } from 'prop-types';

export function CpSelectMultiPillsField(props) {
  const { fieldName, control, required, rules, ...cpInputMultiProps } = props;

  const mergedRules = useMemo(() => {
    let mRules = {};
    if (required) {
      mRules.validate = (v) => {
        return v.length || 'Field is required';
      };
    }
    return { ...mRules, ...rules };
  }, [JSON.stringify(rules)]);

  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({ name: fieldName, control, rules: mergedRules });

  return (
    <CpSelectMultiPills
      {...cpInputMultiProps}
      error={error?.message}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

CpSelectMultiPillsField.propTypes = {
  control: any.isRequired,
  fieldName: string.isRequired,
  rules: object,
  required: bool,
};
