import { useState, useCallback, useEffect } from "react"

/*
 * These values need to be kept manually in sync with src/global-css/media-breakpoints.css
 */

export const mediaQueryMap = {
  "--phone-only": "screen and (max-width: 599px) and (orientation: portrait), screen and (max-height: 599px) and (orientation: landscape)",
  "--tablet-portrait-up": "screen and (min-width: 600px) and (orientation: portrait), screen and (min-height: 600px) and (orientation: landscape)",
  "--tablet-landscape-up": "screen and (min-width: 900px)",
  "--desktop-up": "screen and (min-width: 1200px)",
}

export function useMediaQuery(customQuery) {
  const [matchesQuery, setMatchesQuery] = useState(false)

  const eventHandler = useCallback(
    event => {
      setMatchesQuery(event.matches)
    },
    [setMatchesQuery],
  )

  useEffect(() => {
    const mapped = mediaQueryMap[customQuery]
    if (customQuery) {
      const mediaQueryList = window.matchMedia(mapped ? mapped : customQuery)

      setMatchesQuery(mediaQueryList.matches)
      mediaQueryList.addListener(eventHandler)

      return () => {
        mediaQueryList.removeListener(eventHandler)
      }
    }
  }, [customQuery])

  return matchesQuery
}
