import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'

export function phoneNumber(number, options = {}) {
  const { defaultCountry = 'US' } = options

  if (isValidPhoneNumber(number, defaultCountry)) {
    return parsePhoneNumber(number, defaultCountry).formatNational()
  } else {
    return number
  }
}
