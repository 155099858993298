import React, {useState, useRef} from 'react';
import styles from './options.styles.css';
import {a, m, t} from 'kremling';

export default function Options(props) {
  const {
    options, 
    currentlySelectedOptions, 
    selectId, 
    currentFocus, 
    selectAll,
    selectOption,
    isListWrapped,
    allSelected} = props
  
  return (
    options.map(option => {
      const isSelectAll = option.key === 'select-all';
      const currentlySelectedKeys = currentlySelectedOptions.map(op => op.key);
      return (
        <li
          role="option"
          id={option.key ? `${selectId}-option-${option.key}` : 'selector'}
          key={option.key ? option.key : 'selector'}
          className={a(styles.option).m(styles.focused, currentFocus ? currentFocus.key === option.key : false).m('cps-wt-bold', isSelectAll)}
          data-key={option.key}
          aria-selected={isSelectAll ? allSelected : currentlySelectedKeys.includes(option.key)}
          onClick={isSelectAll ? () => selectAll() : () => selectOption(option)}
        >
          <span className={m(styles.noOverflow, isListWrapped)}>{option.value}</span>
          <label className='cps-checkbox'>
            <input tabIndex={-1} checked={isSelectAll ? allSelected : currentlySelectedKeys.includes(option.key)} type="checkbox" onChange={() => {}}/>
            <span onClick={isSelectAll ?  () => selectAll() : () => selectOption(option)}></span>
          </label>
        </li>
      )}
    )
  )
}
