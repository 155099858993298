export const canopyPhone = '1-855-616-7305'
export const licenseMap = {
  'practiceManagement': 'Practice Management',
  'practiceManagementPro': 'Practice Management Professional',
  'firmManagement': 'Firm Management - Workflow & Advanced Reporting',
  'taxResolution': 'Tax Resolution',
  'irsNotices': 'Notices',
  'transcripts': 'Transcripts & Notices',
  'workflow': 'Workflow',
  'documentManagement': 'Document Management',
  'clientManagement': 'Client Management',
  'timeAndBilling': 'Time & Billing'
}
export const pdfTronLicenseKey = 'Canopy Tax Inc. (canopytax.com):OEM:Canopy::B+:AMS(20230429):AACC7DA307DAC4F30B313BC9B263182F4E6F4FB978CD1B9DF54D09426026DE5AF2B6F5C7'
