import React from 'react';
import { bool, func, node, object, oneOfType, string } from 'prop-types';

import { range } from '@prop-types';
import { ModalContainerComponent } from './modal-container.component';
import { ModalHeader } from './components/modal-header.component';
import { ModalBody } from './components/modal-body.component';
import { CpCard } from '@components';
import { modalWidthMax, modalWidthMin, forceRange, ModalContext as _ModalContext } from './modal.utils';

export function CpModal({ children, width = 424, styles, ...props }) {
    return (
      <ModalContainerComponent
        {...props}
        styles={{
          ...styles,
          width: `${forceRange(width, modalWidthMin, modalWidthMax) / 10}rem`,
          maxHeight: 'calc(100vh - 6.4rem)',
        }}
        animRotateX="-2deg"
        animScale=".9"
      >
        {children}
      </ModalContainerComponent>
    );
}

export const ModalContext = _ModalContext;

CpModal.propTypes = {
  children: node,
  className: oneOfType([object, string]),
  onAfterClose: func,
  onAfterOpen: func,
  onClose: func.isRequired,
  show: bool.isRequired,
  styles: object,
  width: range(modalWidthMin, modalWidthMax),
}

// modal components
CpModal.Header = ModalHeader;
CpModal.Body = ModalBody;
CpModal.Footer = CpCard.Footer;
