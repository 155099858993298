import React, { Component, createRef } from 'react';
import { bool, array, oneOf, oneOfType, object, func } from 'prop-types';
import { Scoped, a } from 'kremling';

import { CpDropdown, CpInput, CpLoader } from '@components';

import styles from './cp-select.styles.pcss';
import {
  clearSelectionId,
  getItemElements,
  scrollToElement,
} from './common/utils';
import { CpSelectContent, RenderContent } from './cp-select-content.component';

export class CpSelectInner extends Component {
  static propTypes = {
    isGroupData: bool.isRequired,
    groupType: oneOf(['header', 'border']),
    isMulti: bool.isRequired,
    isSearch: bool.isRequired,
    data: array.isRequired,
    dataMap: object.isRequired,
    valueTransformed: oneOfType([array, object]),
    clearable: bool,
    onBlur: func,
    preventKeyOpen: bool,
    autoSelectOnSearch: bool,
  };

  dropdownEl = createRef();
  searchEl = createRef();
  selectEl = createRef();
  scrollEl = createRef();
  triggerRef = createRef();

  state = {
    selectedId: null,
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.autoSelectOnSearch &&
      (this.props.searchValue !== prevProps.searchValue ||
        this.props.data !== prevProps.data)
    ) {
      const nodes = getItemElements(this.scrollEl);
      if (nodes && nodes.length) {
        let node = nodes[0];
        if (nodes.length > 1 && node.classList.contains('item-clear')) {
          // If the first node is the "clear selection" node then select the node after if we can
          node = nodes[1];
        }
        this.setState({ selectedId: node.dataset.id }, () =>
          scrollToElement(node)
        );
      }
    }
  }

  onOpen = () => {
    const { insertSearch, onOpen } = this.props;
    if (insertSearch && this.searchEl && this.searchEl.current) {
      setTimeout(() => {
        if (this.searchEl.current) this.searchEl.current.focus();
      }, 0);
    }
    this.setState({ isOpen: true });
    onOpen && onOpen();
  };

  onClose = () => {
    const { searchOnChange, onClose } = this.props;
    this.setState({ isOpen: false, selectedId: null });
    if (searchOnChange) searchOnChange('');
    onClose && onClose();
    setTimeout(() => {
      if (!this.selectEl?.current?.contains(document.activeElement)) {
        this.onBlur();
      }
    }, 0);
  };

  onKeyDown = (e) => {
    const { isOpen, selectedId } = this.state;
    if (!this.dropdownEl.current) return;
    if (isOpen) {
      if (e.key === 'Escape' || e.key === 'Tab') {
        e.preventDefault();
        this.close();
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.onArrowUp();
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.onArrowDown();
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        if (selectedId === clearSelectionId) {
          return this.globalOnChange({ id: clearSelectionId });
        }
        let item = this.getItem(selectedId);
        if (!item) return this.close();
        return this.globalOnChange(item);
      }
    } else {
      if (
        !this.props.preventKeyOpen &&
        (e.key === 'ArrowDown' || e.key.length === 1)
      ) {
        if (e.key === 'ArrowDown') e.preventDefault();
        this.dropdownEl.current.open();
      }
    }
  };

  onArrowUp = () => {
    const nodes = getItemElements(this.scrollEl);
    if (!nodes || !nodes.length) return;
    let node;
    const currentIndex = nodes.findIndex((node) => {
      return node.dataset?.selected === 'true';
    });
    if (currentIndex === -1 || currentIndex === 0) {
      node = nodes[this.itemLength() - 1];
    } else {
      node = nodes[currentIndex - 1];
    }
    this.setState({ selectedId: node.dataset?.id }, () => {
      scrollToElement(node);
    });
  };

  onArrowDown = () => {
    const nodes = getItemElements(this.scrollEl);
    if (!nodes || !nodes.length) return;
    let node;
    const currentIndex = nodes.findIndex(
      (node) => node.dataset?.selected === 'true'
    );
    if (currentIndex === -1 || currentIndex === this.itemLength() - 1) {
      node = nodes[0];
    } else {
      node = nodes[currentIndex + 1];
    }
    this.setState({ selectedId: node.dataset?.id }, () => {
      scrollToElement(node);
    });
  };

  selectItem = (selectedId) => {
    this.setState({ selectedId });
  };

  getItem = (id) => {
    const { data, isGroupData, valueTransformed } = this.props;
    return [
      ...(Array.isArray(valueTransformed) ? valueTransformed : []),
      ...(isGroupData
        ? data.reduce((acc, next) => [...acc, ...next.data], [])
        : data),
    ].find((item) => item.id.toString() === id);
  };

  globalOnChange = (itemTransformed) => {
    const {
      onChange,
      transformData,
      value,
      valueTransformed,
      isMulti,
      dataMap,
    } = this.props;
    let item;
    if (itemTransformed.id === clearSelectionId) {
      item = null;
    } else if (transformData) {
      item = dataMap[itemTransformed.id];
    } else {
      item = itemTransformed;
    }
    // don't allow disabled items to be added
    if (itemTransformed.disabled) return;
    if (!isMulti) {
      onChange(item);
      this.setState({ selectedId: null });
      this.scrollToTop();
      if (this.dropdownEl.current && itemTransformed.id !== clearSelectionId) {
        this.close();
      }
    } else {
      const valIndex = valueTransformed.findIndex((v) => {
        return v.id === itemTransformed.id;
      });
      if (valIndex === -1) {
        onChange([...value, item]);
      } else {
        onChange([...value.slice(0, valIndex), ...value.slice(valIndex + 1)]);
      }
    }
  };

  clearAll = () => {
    const { onChange } = this.props;
    onChange([]);
  };

  onSearch = (value) => {
    const { searchOnChange } = this.props;
    this.setState({ selectedId: null });
    this.scrollToTop();
    if (searchOnChange) {
      searchOnChange(value);
    }
  };

  close = () => {
    const { searchOnChange, insertSearch } = this.props;
    if (searchOnChange) {
      searchOnChange('');
    }
    if (this.dropdownEl.current) {
      this.dropdownEl.current.close();
    }
    if (insertSearch && this.triggerRef && this.triggerRef.current) {
      this.triggerRef.current.focus();
    }
  };

  itemLength = () => {
    const { value, data, isGroupData, isMulti, clearable } = this.props;
    let length = 0;
    if (isGroupData && data.length) {
      length = data.reduce((acc, next) => {
        return acc + next.data.filter((i) => !i.subHeader).length;
      }, 0);
    } else if (data && data.length) {
      length = data.filter((i) => !i.subHeader).length;
    } else if (Array.isArray(value)) {
      length = value.length;
    }
    return length + (!isMulti && value && clearable ? 1 : 0);
  };

  scrollToTop = () => {
    if (this.scrollEl.current) {
      this.scrollEl.current.scrollTop = 0;
    }
  };

  onBlur = () => {
    if (this.props.onBlur) this.props.onBlur();
  };

  render() {
    const {
      appendTo,
      allowContentClicks,
      className,
      contentWidth,
      cover,
      data,
      disabled,
      insertSearch,
      isGroupData,
      groupType,
      isMulti,
      loading,
      position,
      preventOutsideClickUntilClosed,
      renderFooter,
      renderGroup,
      renderItem,
      showResultsInContent,
      searchOnChange,
      searchValue,
      style,
      renderTrigger,
      triggerIsBlock,
      valueTransformed,
      clearable,
    } = this.props;
    const { selectedId } = this.state;
    const RenderFooter = renderFooter;
    return (
      <Scoped css={styles}>
        <div
          ref={this.selectEl}
          onKeyDown={this.onKeyDown}
          style={style}
          className={a('cp-select-component')
            .a(className)
            .m('cp-select-component--block', triggerIsBlock)}
        >
          <CpDropdown
            allowContentClicks={allowContentClicks}
            appendTo={appendTo}
            contentWidth={contentWidth}
            cover={cover}
            disabled={disabled}
            onOpen={this.onOpen}
            onClose={this.onClose}
            position={position}
            preventOutsideClickUntilClosed={preventOutsideClickUntilClosed}
            ref={this.dropdownEl}
            triggerIsBlock={triggerIsBlock}
            renderTrigger={(triggerProps) => (
              <div>
                {renderTrigger({
                  ...triggerProps,
                  data,
                  isDisabled: disabled,
                  contentWidth,
                  onChange: this.globalOnChange,
                  ref: this.triggerRef,
                  searchOnChange: this.onSearch,
                  searchValue,
                  triggerIsBlock,
                  triggerOnBlur: this.onBlur,
                  value: valueTransformed,
                })}
              </div>
            )}
            renderContent={({ close, isOpen }) => (
              <Scoped css={styles}>
                <div className="cp-select-component__content">
                  {insertSearch && (
                    <div className="cp-search">
                      <CpInput
                        onChange={this.onSearch}
                        ref={this.searchEl}
                        value={searchValue}
                        isSearch
                      />
                    </div>
                  )}
                  {loading ? (
                    <div className="cp-select-component__loader">
                      <CpLoader center />
                    </div>
                  ) : (
                    <RenderContent shouldUpdate={isOpen}>
                      <CpSelectContent
                        data={data}
                        disabled={disabled}
                        isGroupData={isGroupData}
                        groupType={groupType}
                        isMulti={isMulti}
                        onChange={this.globalOnChange}
                        ref={this.scrollEl}
                        renderGroup={renderGroup}
                        renderItem={renderItem}
                        searchOnChange={searchOnChange}
                        searchValue={searchValue}
                        selectedId={selectedId}
                        selectItem={this.selectItem}
                        showResultsInContent={showResultsInContent}
                        valueTransformed={valueTransformed}
                        clearable={clearable}
                      />
                    </RenderContent>
                  )}
                  {!!renderFooter && (
                    <div className="cp-select-component-footer">
                      <RenderFooter
                        close={close}
                        value={valueTransformed}
                        clearAll={this.clearAll}
                      />
                    </div>
                  )}
                </div>
              </Scoped>
            )}
          />
        </div>
      </Scoped>
    );
  }
}
