import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useCss, a } from "kremling";
import Tippy from "@tippyjs/react/headless";

import { deprecatedOneOf } from "@prop-types";
import { useIsMounted } from "@hooks";
import styles from "./cp-dropdown.styles.pcss";
import {
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from "prop-types";
import {
  useContentWidth,
  useDropdownPlacementConversion,
  getAnimationDuration,
  useAutoDisable,
  hideOnEsc,
  dropdownPositionOptions,
} from "./cp-dropdown.utils";
import { createPortal } from "react-dom";
import { clickEventStack } from "@helpers";

const animationDuration = getAnimationDuration();

export const CpDropdown = forwardRef(function CpDropdown(props, ref) {
  const {
    allowContentClicks = false,
    appendTo = document.body,
    contentHeight = "auto",
    contentWidth = "sm",
    cover,
    disabled,
    position = "bottom",
    onClose,
    onOpen,
    preventOutsideClickUntilClosed,
    renderContent,
    renderTrigger,
    renderWhenClosed = true,
  } = props;
  const scope = useCss(styles);
  const unmountTimeoutRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [instance, setInstance] = useState(null);
  const isMounted = useIsMounted();
  const calculatedContentWidth = useContentWidth(contentWidth);
  const placement = useDropdownPlacementConversion(position);
  const clickEventStackRef = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        instance?.popperInstance?.update();
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      if (!preventOutsideClickUntilClosed) {
        clickEventStackRef.current?.remove();
      }
    };
  }, []);

  const documentClickHandler = useCallback(
    (e) => {
      if (!instance) return;
      // pressing the trigger - don't do anything
      if (instance.reference.contains(e.target)) return;
      const contains = instance.popper.contains(e.target);
      if ((contains && !allowContentClicks) || !contains) {
        close();
      }
    },
    [instance, allowContentClicks]
  );

  function open() {
    instance.show();
  }

  function close() {
    if (!preventOutsideClickUntilClosed) {
      clickEventStackRef.current?.remove();
    }
    instance.hide();
  }

  function toggle() {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }

  function onShow({ popper, reference }) {
    if (instance) {
      if (onOpen) onOpen();
      if (contentWidth === "block") {
        if (placement.includes("left") || placement.includes("right")) {
          popper.style.height =
            reference.getBoundingClientRect().height / 10 + "rem";
        } else {
          popper.style.width =
            reference.getBoundingClientRect().width / 10 + "rem";
        }
      }
      if (!preventOutsideClickUntilClosed) {
        clickEventStackRef.current = clickEventStack.add(documentClickHandler);
      }
    }
  }

  function onHide({ unmount }) {
    if (isMounted.current) {
      setIsOpen(false);
      if (onClose) onClose();
    }
    // wait for animation to finish before unmounting
    unmountTimeoutRef.current = setTimeout(() => {
      if (instance && isMounted.current) {
        setIsVisible(false);
        unmount();
      }
    }, animationDuration);
  }

  function onMount() {
    setIsOpen(true);
    setIsVisible(true);

    if (unmountTimeoutRef.current) {
      clearTimeout(unmountTimeoutRef.current);
    }
  }

  let coverOffset = [0, 0];
  if (cover && instance) {
    const rect = instance.reference.getBoundingClientRect();
    if (placement.includes("bottom") || placement.includes("top")) {
      coverOffset[1] = rect.height * -1;
    } else if (placement.includes("left") || placement.includes("right")) {
      coverOffset[1] = rect.width * -1;
    }
  }

  if (ref) {
    const current = {
      open,
      close,
      toggle,
      isOpen,
    };
    if (typeof ref === "function") {
      ref({ current });
    } else {
      ref.current = current;
    }
  }

  useAutoDisable(disabled, isOpen, close);

  return (
    <Tippy
      allowHTML
      animation
      appendTo={appendTo}
      disabled={disabled}
      hideOnClick={false}
      interactive
      offset={coverOffset}
      onCreate={setInstance}
      onHide={onHide}
      onMount={onMount}
      onShow={onShow}
      maxWidth={240}
      placement={placement}
      plugins={[hideOnEsc]}
      trigger="manual"
      zIndex={100001}
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
            },
          },
        ],
      }}
      render={(attrs) => {
        return (
          <>
            {preventOutsideClickUntilClosed &&
              isOpen &&
              createPortal(
                <div
                  {...scope}
                  className="cp-dropdown__prevent-click"
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                  }}
                />,
                document.body
              )}
            <div
              {...attrs}
              {...scope}
              className={a("cp-dropdown").m("cp-dropdown--is-open", isOpen)}
              tabIndex="-1"
              style={{
                maxHeight: contentHeight,
                width: calculatedContentWidth,
              }}
            >
              {!!(isVisible || renderWhenClosed) &&
                renderContent({ isOpen, close })}
              <div className="cp-tooltip-arrow" data-popper-arrow="" />
            </div>
          </>
        );
      }}
    >
      {!!renderTrigger &&
        renderTrigger({
          isOpen,
          toggle,
          open,
          close,
        })}
    </Tippy>
  );
});

CpDropdown.propTypes = {
  allowContentClicks: bool,
  appendTo: oneOfType([object, oneOf(["parent"])]),
  contentHeight: oneOfType([string, number]),
  contentWidth: oneOfType([number, oneOf(["sm", "md", "lg", "block"])]),
  cover: bool,
  disabled: bool,
  onBlur: func,
  onOpen: func,
  onClose: func,
  position: deprecatedOneOf(
    dropdownPositionOptions, // valid options
    [
      "top-left",
      "top-right",
      "right-top",
      "right-bottom",
      "bottom-right",
      "bottom-left",
      "left-bottom",
      "left-top",
    ] // deprecated options
  ),
  preventOutsideClickUntilClosed: bool,
  renderTrigger: func,
  renderContent: func.isRequired,
};
