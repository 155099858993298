import sanitizeHtml from 'sanitize-html';
import DOMPurify from 'dompurify';

const whitelistedClosingTags = ['div', 'span', 'p', 'ul', 'ol', 'li', 'b', 'strong', 'i', 'em', 'u'];
const whitelistedSelfClosingTags = ['br'];
const whitelistedTags = [...whitelistedClosingTags, ...whitelistedSelfClosingTags];

const domPurifyConfig = {
  ALLOWED_TAGS: ['html', 'body', '#text', ...whitelistedTags],
  KEEP_CONTENT: false,
}

const sanitizeHtmlConfig = {
  allowedTags: whitelistedTags,
  selfClosing: whitelistedSelfClosingTags,
  allowedAttributes: {},
  allowedClasses: {},
  allowedStyles: {},
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: [],
  allowProtocolRelative: false,
  transformTags: {},
}

export function sanitize(suspectHtml) {
  const domPurifyPassHtml = DOMPurify.sanitize(suspectHtml, domPurifyConfig);
  return sanitizeHtml(domPurifyPassHtml, sanitizeHtmlConfig);
}