import React, { useMemo } from "react";
import { CpRadio } from '@components';
import { useController } from 'react-hook-form';
import { any, object, string } from 'prop-types';

export function CpRadioField(props) {
  const { fieldName, control, required, rules, ...CpRadioProps } = props;

  const mergedRules = useMemo(() => {
    let mRules = {};
    if (required) {
      mRules.required = 'Field is required';
    }
    return { ...mRules, ...rules };
  }, [JSON.stringify(rules), required]);

  const {
    field: { value, onBlur, onChange },
    fieldState: { error },
  } = useController({ name: fieldName, control, rules: mergedRules });

  return (
    <CpRadio
      {...CpRadioProps}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      error={error?.message}
    />
  );
}

CpRadioField.Item = CpRadio.Item;

CpRadioField.propTypes = {
  control: any.isRequired,
  fieldName: string.isRequired,
  rules: object,
};
