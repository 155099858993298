// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".options-styles__focused--Dk-5W {\n  background-color: var(--cps-color-chrome);\n}\n\n.options-styles__noOverflow--MKxbL {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.options-styles__option--Hjj4c {\n  min-height: 40px;\n  padding: 0 10px;\n  display: flex;\n  align-items: center;\n  cursor: default;\n  justify-content: space-between;\n}\n\n.options-styles__option--Hjj4c:hover {\n  background-color: var(--cps-color-chrome);\n}", "",{"version":3,"sources":["webpack://src/custom-elements/cps-short-multiselect/listbox/options/options.styles.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;AAC3C;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".focused {\n  background-color: var(--cps-color-chrome);\n}\n\n.noOverflow {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.option {\n  min-height: 40px;\n  padding: 0 10px;\n  display: flex;\n  align-items: center;\n  cursor: default;\n  justify-content: space-between;\n}\n\n.option:hover {\n  background-color: var(--cps-color-chrome);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"focused": "options-styles__focused--Dk-5W",
	"noOverflow": "options-styles__noOverflow--MKxbL",
	"option": "options-styles__option--Hjj4c"
};
export default ___CSS_LOADER_EXPORT___;
