import React from 'react';
import {
  func,
  string,
  array,
  bool,
  oneOf,
  oneOfType,
  object,
} from 'prop-types';
import { CpDropdown } from '@components';

import { CpSelect } from '../cp-select/cp-select.component';
import { defaultSearchFilter, filterAlpha } from '../cp-select/common/utils';
import styles from './../cp-select/cp-select.styles.pcss';
import { Scoped } from 'kremling';

export function CpSelectMulti(props) {
  const {
    appendTo,
    className,
    contentWidth,
    data,
    disabled,
    error,
    insertSearch,
    isGroupData,
    groupType,
    loading,
    onBlur,
    onChange,
    onClose,
    onOpen,
    placeholder = 'Select',
    preventKeyOpen,
    preventOutsideClickUntilClosed,
    renderFooter,
    renderTrigger,
    renderValue,
    searchFilter,
    searchOnChange,
    searchValue,
    transformData,
    triggerIsBlock,
    value,
  } = props;
  return (
    <Scoped css={styles}>
      <CpSelect
        appendTo={appendTo}
        className={className}
        contentWidth={contentWidth}
        data={data}
        disabled={disabled}
        insertSearch={insertSearch}
        isGroupData={isGroupData}
        groupType={groupType}
        loading={loading}
        onChange={onChange}
        onClose={onClose}
        onOpen={onOpen}
        preventKeyOpen={preventKeyOpen}
        preventOutsideClickUntilClosed={preventOutsideClickUntilClosed}
        renderFooter={renderFooter || CpSelect.Footer}
        renderGroup={CpSelect.Group}
        renderItem={CpSelect.ItemMulti}
        renderTrigger={renderTrigger || ((triggerProps) => (
          <CpSelect.TriggerMulti
            {...triggerProps}
            onBlur={onBlur}
            placeholder={placeholder}
            renderValue={renderValue}
            error={error}
          />
        ))}
        searchFilter={defaultSearchFilter(searchFilter, searchValue)}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        showResultsInContent
        transformData={transformData}
        triggerIsBlock={triggerIsBlock}
        value={value}
      />
      {typeof error === 'string' && (
        <div className="cp-select--error-text">{error}</div>
      )}
    </Scoped>
  );
}

CpSelectMulti.propTypes = {
  appendTo: CpDropdown.propTypes.appendTo,
  preventOutsideClickUntilClosed: CpDropdown.propTypes.preventOutsideClickUntilClosed,

  className: oneOfType([string, object]),
  data: array,
  error: oneOfType([bool, string]),
  filter: func,
  insertSearch: bool,
  isGroupData: bool,
  groupType: oneOf(['header', 'border']),
  loading: bool,
  onBlur: func,
  onChange: func.isRequired,
  onClose: func,
  onOpen: func,
  placeholder: string,
  preventKeyOpen: CpSelect.propTypes.preventKeyOpen,
  renderFooter: CpSelect.propTypes.renderFooter,
  renderTrigger: CpSelect.propTypes.renderTrigger,
  renderValue: func,
  searchOnChange: func,
  searchValue: string,
  transformData: func,
  triggerIsBlock: CpSelect.propTypes.triggerIsBlock,
  value: array.isRequired,
};

CpSelectMulti.filterAlpha = filterAlpha;
