import { useEffect } from 'react';
import { modalService } from '@helpers';

export const drawerWidthMin = 320;
export const drawerWidthMax = 424;

export const DrawerMount = () => {
  useEffect(() => {
    modalService.__openModal(false);
    return () => modalService.__closeModal();
  }, []);
  return null;
}
