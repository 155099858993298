import React, {useState, useRef, useEffect} from 'react';
import styles from './listbox.styles.css';
import {a, m, t} from 'kremling';
import Options from './options/options.component.js';
import Footer from './footer/footer.component.js';
import {minBy} from 'lodash'

export default function Listbox(props) {
  const {selections, updateSelections, buttonId, listWidth, options, isListWrapped, labelId, selectId, hideOptions, toggleShowOptions} = props
  const [currentFocus, setCurrentFocus] = useState(null)
  const [currentlySelectedOptions, setCurrentlySelectedOptions] = useState(selections)
  
  const allSelected = currentlySelectedOptions.length === options.length
  const selectAllValue = allSelected ? 'Deselect All' : 'Select All'
  const selectAllOptions = [{key: 'select-all', value: selectAllValue}, ...options]
  const listboxRef = useRef(null);
  const containerRef = useRef(null);
  const footerRef = useRef(null)
  useScroll();
  useFocus();
  useClickListener();
  
  return (
    <div className={a(styles.open)} style={{width: `${listWidth}`}} ref={containerRef}>
        <ul
          role='listbox'
          aria-labelledby={labelId}
          aria-multiselectable={true}
          aria-activedescendant={currentFocus ? `${selectId}-option-${currentFocus.key}` : null}
          className={a(styles.listbox)}
          ref={listboxRef}
          onKeyDown={keyDown}
          id={`listbox-${labelId}`}
          tabIndex={0}
          >
            <Options 
              options={selectAllOptions}
              currentlySelectedOptions={currentlySelectedOptions}
              selectId={selectId}
              currentFocus={currentFocus}
              isListWrapped={isListWrapped}
              selectAll={selectAll}
              selectOption={selectOption}
              allSelected={allSelected}
            />
        </ul>
        <Footer 
          setCurrentFocus={setCurrentFocus} 
          hideOptions={hideOptions} 
          buttonId={buttonId} 
          updateSelections={updateSelections}
          setCurrentlySelectedOptions={setCurrentlySelectedOptions}
          currentlySelectedOptions={currentlySelectedOptions}
          selections={selections}
        />
    </div>
  )
  
  function useFocus() {
    useEffect(() => {
        listboxRef.current.focus();
    }, [])
  }

  function keyDown(evt) {
    if (evt.key === ' ') {
      evt.preventDefault();
      if (currentFocus) {
        currentFocus.key === 'select-all' ? selectAll() : selectOption(currentFocus);
      }
    }

    if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') {
      evt.preventDefault();
      const focusItemIndex = currentFocus ? getCurrentFocus(evt) : 0;
      setCurrentFocus(selectAllOptions[focusItemIndex]);
    }
    
    if (evt.key === 'Tab') {
      setCurrentFocus(null);
      listboxRef.current.blur();
    }

    if (evt.key === 'Enter') {
      evt.preventDefault();
      toggleShowOptions();
    }
  }
  
  function getCurrentFocus(evt) {
    const currentIndex = selectAllOptions.findIndex(op => op.key === currentFocus.key)
    let focus
    if (evt.key === 'ArrowDown') {
      focus = currentIndex === selectAllOptions.length - 1 ? currentIndex : currentIndex + 1
    } else if (evt.key === 'ArrowUp') {
      focus = currentIndex === 0 ? currentIndex : currentIndex - 1
    } 
    return focus 
  }
  
  function useScroll() {
    useEffect(() => {
      if (selections.length || currentFocus) {
        const firstItem = getFirstSelection()
        const scrollItem = currentFocus ? currentFocus : firstItem
        const scrollToElement = listboxRef.current.querySelector(`li[data-key="${scrollItem.key}"]`)
        const scrollTop = listboxRef.current.scrollTop
        const scrollBottom = scrollTop + listboxRef.current.clientHeight
        const needToScrollIntoView = scrollToElement.offsetTop + scrollToElement.offsetHeight <= scrollTop || scrollToElement.offsetTop >= scrollBottom
        const doneButtonFocused = document.activeElement.id === 'done-' + buttonId

        needToScrollIntoView ? scrollToElement.scrollIntoView() : null
        setCurrentFocus(doneButtonFocused ? null : scrollItem)
      }
    }, [currentFocus])
  }
  
  function getFirstSelection() {    
    return minBy(selections, (selection) => {
      return selectAllOptions.findIndex(option => option.key === selection.key)
    })
  }
  
  function selectAll() {
    setCurrentFocus(selectAllOptions[0])
    setCurrentlySelectedOptions(prevOptions => {
      if (prevOptions.length === options.length) {
        return []
      } else {
        return options
      }
    })
  }
    
  function selectOption(option) {
    setCurrentFocus(option)
    setCurrentlySelectedOptions(prevOptions => {
      if (prevOptions.map(op => op.key).includes(option.key)) {
        const currentOptions = prevOptions.filter(prevOption => prevOption.key !== option.key)
        return currentOptions
      } else {
        
        return [...prevOptions, option]
      }
    })
  }
  
  function useClickListener() {
    useEffect(() => {
      document.addEventListener('click', closeOptions)
      return () => document.removeEventListener('click', closeOptions)
    }, [])
  
    function closeOptions(evt) {
      if (containerRef && containerRef.current) {
        if (containerRef === evt.target || containerRef.current.contains(evt.target)) {
          return
        }
        setCurrentlySelectedOptions(selections)
        setCurrentFocus(null)
        hideOptions()
      }
    }
  }
}