import React from "react";
import { oneOf, number, bool, oneOfType, string } from 'prop-types';
import { Scoped, k, a } from "kremling";

const colors = {
  dark: ['rgba(0,0,0,.3)','rgba(0,0,0,.3)','rgba(0,0,0,.3)'],
  light: ['rgba(255,255,255,.7)','rgba(255,255,255,.7)','rgba(255,255,255,.7)'],
};

export function CpLoader({ dark = false, size = 'md', center, className }){
  const chosenColor = dark ? colors.light : colors.dark;
  let sizePx = getSize(size);
  return (
    <Scoped css={css}>
      <div className={a('loader-wrapper').m('loader-wrapper--center', center).a(className)}>
        <div style={{ width: `${sizePx/10}rem`, height: `${sizePx/20}rem`, }}>
          <svg viewBox="0 0 100 50">
            <g transform="translate(25 25)">
              <circle cx="0" cy="0" r="8" fill={chosenColor[0]} transform="scale(0.00355393 0.00355393)">
                <animateTransform attributeName="transform" type="scale" begin="-0.3333333333333333s"
                                  calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0"
                                  keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
              </circle>
            </g>
            <g transform="translate(50 25)">
              <circle cx="0" cy="0" r="8" fill={chosenColor[1]} transform="scale(0.315084 0.315084)">
                <animateTransform attributeName="transform" type="scale" begin="-0.16666666666666666s"
                                  calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0"
                                  keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
              </circle>
            </g>
            <g transform="translate(75 25)">
              <circle cx="0" cy="0" r="8" fill={chosenColor[2]} transform="scale(0.775459 0.775459)">
                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s"
                                  repeatCount="indefinite" />
              </circle>
            </g>
          </svg>
        </div>
      </div>
    </Scoped>
  );
}

CpLoader.propTypes = {
  dark: bool,
  size: oneOfType([number, oneOf(['sm', 'md', 'lg'])]),
  center: bool,
  className: string
};

function getSize(size) {
  switch(size) {
    case 'sm':
      return 32;
    case 'md':
      return 56;
    case 'lg':
      return 80;
    default:
      return size;
  }
}

const css = k`
  .loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader-wrapper--center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader-wrapper > svg {
    width: 5rem;
    height: 5rem;
  }
`;
