import React from 'react';
import { useCss, a } from 'kremling';
import { bool, object, oneOfType, string, number } from 'prop-types';

export function CpHeader({ children, border, className, level, ...rest }) {
  const scope = useCss(css);
  return (
    <div
      {...scope}
      className={
        a('cp-header-component')
          .m('cp-header-component__l1', level === 1)
          .m('cp-header-component__l2', level === 2 || !level)
          .m('cp-header-component__l3', level === 3)
          .m('cp-header-component__border', border)
          .a(className)
      }
      {...rest}
    >
      {children}
    </div>
  );
}

CpHeader.defaultProps = {
  border: true,
}

CpHeader.propTypes = {
  border: bool,
  className: oneOfType([object, string]),
  level: number,

};

const css = `
  .cp-header-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6rem;
    flex-shrink: 0;
  }
  
  .cp-header-component__border {
    border-bottom: solid .1rem var(--cp-color-card-border);    
  }
  
  .cp-header-component__l1 {
    height: 4.8rem;
  }

  .cp-header-component__l2 {
    height: 5.6rem;
  }

  .cp-header-component__l3 {
    height: 6.4rem;
  }
`;