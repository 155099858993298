import { useCss } from "kremling";
import React from "react";
import PropTypes, { string } from "prop-types";

export function CpBadge({ color, text, onClick, disabled }) {
  const El = onClick ? "button" : "div";
  const scope = useCss(css)
  return (
    <El
      type={`${onClick ? "button" : ""}`}
      {...scope}
      className="cp-badge"
      style={{'--badge-color': color}}
      onClick={onClick && !disabled ? onClick : undefined}
      aria-disabled={disabled}
    >
      <div className="text" style={{color: disabled ? 'var(--cp-color-app-disabled-text)' : "inherit"}}>{text}</div>
    </El>
  );
}

CpBadge.propTypes = {
  color: string.isRequired,
  text: string.isRequired,
};

const css = `
  & .cp-badge:focus {
    box-shadow: var(--cp-form-focus-state);
    /* Visible in Windows high-contrast themes https://stackoverflow.com/a/52616313/5671022 */
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }
  & .cp-badge {
    /* remove button styling */
    color: inherit;
    padding: 0;
    font: inherit;

    border-radius: 5px;
    border: 1px solid var(--cps-color-silver);
    align-items: center;
    padding-left: .8rem;
    background: var(--cp-color-app-primary-dark-text);
    cursor: pointer;
    background-clip: padding-box;
    position: relative;
    display: inline-block;
  }
  & .text {
    margin: 0px 8px;
  }
  .text::before {
    background-color: var(--badge-color);
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    left: 0;
    border-radius: 5px 0 0 5px;
    border-right: var(--cps-color-silver) 1px solid;
  }
`;
