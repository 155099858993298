import React, { forwardRef, useEffect } from 'react';
import { string, oneOf, bool, func } from 'prop-types';
import { useCss, a, m } from 'kremling';

import { CpIcon, CpLoader } from '@components';

const allowedBtnTypes = ['primary', 'secondary', 'tertiary', 'flat', 'icon', 'icon-dark', 'icon-active', 'icon-raised'];

export const CpButton = forwardRef(function CpButton(props, ref) {
  const {
    anchor,
    block = false,
    btnType = !props.children && props.icon ? 'icon' : 'primary',
    children,
    className,
    disabled,
    dropdown,
    icon,
    showLoader,
    small,
    ...rest
  } = props;
  const scope = useCss(css);
  const TagWrapper = anchor ? 'a' : 'button';
  const btnProps = { ...rest };

  useEffect(() => {
    if (icon && !children && !props['aria-label']) {
      console.warn(`<CpButton> "aria-label" attribute is required when using the "icon" prop. (${icon})`);
    }
  }, [icon, children, props['aria-label']])

  const isIcon = btnType.includes('icon');

  return (
    <TagWrapper
      {...scope}
      {...btnProps}
      ref={ref}
      disabled={disabled || showLoader}
      className={
        m('cp-button', allowedBtnTypes.includes(btnType) || icon)
          .m(`cp-button--icon`, isIcon)
          .m(`cp-button--${btnType}`, btnType)
          .m(`cp-button--text-icon`, icon && children)
          .m(`cp-button--disabled`, disabled)
          .m(`cp-button--show-loader`, showLoader)
          .m(`cp-button--dropdown`, dropdown)
          .m(`cp-button--block`, block)
          .m(`cp-button--small`, small)
          .a(className)
      }
    >
      <span className={
        a('cp-button__content')
          .m('cp-button__content--block', dropdown && block)
      }>
        {icon && (
          <CpIcon className={m('cp-mr-8', !!children)} name={icon} />
        )}
        <span className="cp-button__inner">{children}</span>
        {dropdown && (
          <CpIcon name="caret-small-down" className="cp-button__caret" />
        )}
      </span>
      {showLoader && (
        <div className="cp-button-loader">
          <CpLoader dark={btnType === 'primary'} />
        </div>
      )}
    </TagWrapper>
  );
});

CpButton.propTypes = {
  anchor: bool,
  block: bool,
  btnType: oneOf([...allowedBtnTypes, 'unstyled']),
  disabled: bool,
  dropdown: bool,
  icon: string,
  label: string,
  onClick: func,
  showLoader: bool,
  small: bool,
};

const css = `
  .cp-button {
    height: 3.2rem;
    border-radius: var(--cp-form-border-radius);
    border: none;
    cursor: pointer;
    padding: 0 1.6rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    transition: box-shadow var(--cp-form-transition-duration) ease, border var(--cp-form-transition-duration) ease;
    user-select: none;
  }

  .cp-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity var(--cp-form-transition-duration) ease;
  }

  .cp-button:hover {
    text-decoration: none;
  }

  .cp-button:hover::after {
    opacity: .07;
  }

  .cp-button:active::after {
    opacity: .1;
  }

  .cp-button:active, .cp-button:focus {
    outline: none;
    box-shadow: none;
  }

  .cp-button:focus:not(:active) {
    box-shadow: var(--cp-form-focus-state);
  }

  .cp-button.cp-button--disabled {
    cursor: default;
  }

  .cp-button.cp-button--disabled::after {
    display: none;
  }

  .cp-button__content {
    line-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .cp-button__content--block {
    line-height: 3rem;
    justify-content: space-between;
    text-align: left;
    width: 100%;
  }

  .cp-button__content--block svg {
    flex-shrink: 0;
  }

  .cp-button__inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
  }

  .cp-button__caret {
    margin-right: -.2rem;
    margin-left: .2rem;
  }

  /** PRIMARY **/
  .cp-button--primary {
    background-color: var(--cp-color-app-primary);
    color: #fff;
  }
  .cp-button--primary.cp-button--disabled {
    background-color: rgba(var(--cp-color-app-primary-rgb), .4);
  }
  .cp-button-primary.cp-button--disabled:hover {
    border: none;
  }

  /** SECONDARY **/
  .cp-button--secondary {
    background-color: var(--cps-color-ash);
    border: solid .1rem var(--cps-color-border);
    color: var(--cps-color-primary-text);
  }
  .cp-button--secondary:focus {
    border-color: rgba(var(--cps-color-af-rgb), .9);
  }
  .cp-button--secondary.cp-button--disabled {
    background-color: var(--cps-color-background);
    color: rgba(var(--cps-color-primary-text-rgb), .4);
  }


  /** TERTIARY **/
  .cp-button--tertiary {
    background-color: transparent;
    font-weight: 400;
    color: var(--cps-color-primary-text);
  }
  .cp-button--tertiary.cp-button--disabled {
    color: rgba(var(--cps-color-primary-text-rgb), .4);
  }

  /** FLAT **/
  .cp-button--flat {
    color: var(--cp-color-app-primary);
    background-color: transparent;
  }
  .cp-button--flat.cp-button--disabled {
    color: rgba(var(--cp-color-app-primary-rgb), .6);
  }

  /** ICON **/
  .cp-button--icon {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    color: var(--cps-color-default-icon);
    background-color: transparent;
    font-weight: 400;
  }
  .cp-button--icon.cp-button--disabled {
    color: rgba(var(--cps-color-default-icon-rgb), .4);
  }

  /** ICON + TEXT **/
  .cp-button--text-icon {
    padding-left: .9rem;
  }

  /** ICON-DARK **/
  .cp-button--icon-dark {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    color: var(--cps-color-light-primary-text);
    background-color: transparent;
    font-weight: 400;
  }
  .cp-button--icon-dark:focus:not(:active) {
    box-shadow: var(--cp-form-focus-state-dark);
  }
  .cp-button--icon-dark.cp-button--disabled {
    color: var(--cps-color-light-primary-text);
    opacity: .5;
  }
  .cp-button--icon-dark::after {
    background-color: #FFF;
  }

  /** ICON-ACTIVE **/
  .cp-button--icon-active {
    color: var(--cp-color-button-icon-active-text);
  }

  /** ICON-RAISED **/
  .cp-button--icon-raised {
    background-color: var(--cp-color-button-icon-raised-bg);
    border-radius: 50%;
    overflow: visible;
  }
  .cp-button--icon-raised:after {
    border-radius: 50%;
    overflow: hidden;
  }
  .cp-button--icon-raised::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    -webkit-box-shadow: -1px 3px 5px 0px rgb(0 0 0 / 24%);
    box-shadow: -1px 3px 5px 0px rgb(0 0 0 / 24%);
  }

  /** SHOW LOADER **/
  .cp-button--show-loader {
    cursor: default;
  }
  .cp-button--show-loader::after {
    display: none;
  }
  .cp-button--show-loader > span {
    visibility: hidden;
  }

  /** SMALL **/
  .cp-button--small {
    height: 2.4rem;
    min-height: 2.4rem;
    line-height: 2.4rem;
    padding: 0 .8rem;
    font-size: 1.2rem;
  }
  .cp-button--small .cp-button__content {
    line-height: 2.4rem;
  }

  .cp-button--small.cp-button--icon {
    padding: 0;
    width: 2.4rem;
  }

  .cp-button--small.cp-button--icon svg {
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0rem;
    top: 0rem;
  }

  .cp-button--small.cp-button--icon-raised svg {
    padding: 0.2rem;
  }

  .cp-button-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cp-button--dropdown,
  a.cp-button--dropdown {
    padding-right: .8rem;
  }

  .cp-button--block,
  a.cp-button--block {
    width: 100%;
    display: block;
  }
`;
