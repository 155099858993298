import React from 'react';
import { useCss, k, a } from 'kremling';

export const CardFooter = ({ children, className, ...rest }) => {
  const scope = useCss(styles);
  return (
  <div className={a("cp-card__footer", className)} {...scope} {...rest}>
    {children}
  </div>
  )
};

const styles = k`
  .cp-card__footer {
    padding: 1.6rem;
    border-top: .1rem solid var(--cps-color-border);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;
