import React from "react";
import { number, node, string, oneOfType, object } from "prop-types";
import { a } from "kremling";

export function CpWell({ children, level, className, ...rest}) {

  return (
    <div
      className={
        a(className)
          .m('cp-well-l1', !level || level === 1)
          .m('cp-well-l2', level === 2)
          .m('cp-well-l3', level === 3)
      }
      {...rest}
    >
      {children}
    </div>
  )
}

CpWell.propTypes = {
  level: number,
  children: node.isRequired,
  className: oneOfType([string, object])
};
