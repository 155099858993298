import React from 'react';
import PropTypes from 'prop-types';
import { isNull, isUndefined } from 'lodash';
import { CprLoader } from '../../../custom-elements/cps-loader/cps-loader.component';
import styles from './filter.styles.css';

const FixedFilter = ({ filterValues, applyFilter, selections, allowMultiple = true }) => {
  return (
    <div>
      <li className="cps-divider cps-margin-left-16 cps-margin-right-16 cps-margin-top-4 cps-margin-bottom-4" />
      {filterValues && filterValues.length > 0
        ? filterValues.map(value => {
          return (
            <li key={`${value.filter}`} className="cps-cursor-pointer">
              <div
                className={`${styles.spreadContents} cps-margin-left-16 cps-margin-right-16 cps-padding-top-8 cps-padding-bottom-8`}
                onClick={e => applyFilter(e, value.filter, allowMultiple)}
                style={{ lineHeight: '20px' }}>
                <div
                  className={`cps-ellipsis ${
                    selections && selections.includes(value.filter) ? 'cps-color-primary' : ''
                    }`}>
                  {value.label}
                </div>
                <div style={{ minWidth: '25px', margin: '-3px' }}>
                  {selections && selections.includes(value.filter) && (
                    <i className="cps-color-primary cps-icon cps-icon-sm-check" />
                  )}
                </div>
              </div>
            </li>
          );
        })
        : (isNull(filterValues) || isUndefined(filterValues)) && <CprLoader />}
    </div>
  );
};

FixedFilter.propTypes = {
  filterValues: PropTypes.array.isRequired,
  applyFilter: PropTypes.func.isRequired,
  selections: PropTypes.array,
};

export default FixedFilter;
